<template>
    <div class="map-page map-page-e-learning">
        <img src="@/assets/images/mobile/e-learning-header.jpg" class="map-page-header-img" />
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Discover Our Courses</h1>
            <div class="map-center-access">
                <p>You have access to course posts from:</p>
                <router-link
                    :to="{ name: center }"
                    v-for="(center, key) in user.centers"
                    :key="key"
                    >
                    {{ center }}
                </router-link>
            </div>
            <articles :style="'e-learning'" :loading="loading">
                <p v-if="!posts || !posts.length">There are no courses</p>
                <course-post
                    v-for="(course, key) in posts"
                    :key="key"
                    :featured="key === 0"
                    :image="course.cover_image"
                    :title="course.title"
                    :excerpt="course.summary"
                    :points="course.points"
                    :progress="course.progress"
                    :link="'/e-learning/course/' + course.id"
                    >
                </course-post>
            </articles>
            <button class="map-button map-load-more" v-if="has_more_pages" @click.prevent="load_more">LOAD MORE</button>
        </div>
        <map-sidebar class="map-sidebar-e-learning">
        </map-sidebar>
    </div>
</template>

<script>
    import Articles from '@/components/Articles/Articles';
    import CoursePost from '@/components/Articles/CoursePost';
    import MapSidebar from '@/components/MapSidebar';

    import { PostService, PostType, CountryService, TopicService, TagService } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            Articles,
            CoursePost,
            MapSidebar,
        },
        data()
        {
            return {
                loading: true,
                has_more_pages: false,
                per_page: 12,
                last_page: 1,
                page: 1,
                posts:[],
                topics: [],
                tags: [],
                countries: [],
                filters: [
                    'Country',
                    'Language',
                    'Tag',
                ],
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            this.loading = true;
            await this.get_posts();

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;
            this.tags = (await TagService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.loading = false;
        },
        methods:
        {
            async get_posts()
            {
                const response = (await PostService.all(PostType.E_LEARNING, {
                    page: this.page,
                    country: this.filters.country,
                    topic: this.filters.topic,
                    tag: this.filters.topic,
                    exclude: 'content,lessons.excerpt,lessons.content,lessons.summary,lessons.lessons,lessons.elearning',
                    'per-page': this.per_page,
                    'order-by': 'featured,created_at',
                })).data;
                const posts = response.data;

                if (this.page === 1)
                {
                    this.posts = posts;
                }
                else
                {
                    this.posts.push(...posts);
                }

                this.posts.forEach((post) =>
                {
                    const lessons_count = post.lessons.length;
                    const done_lessons = post.lessons.filter((l) => !!l.is_done).length;

                    post.progress = (lessons_count === done_lessons ? 'done' : done_lessons === 0 ? 'start' : 'in progress');
                });

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async load_more()
            {
                this.loading = true;

                await this.get_posts();

                this.loading = false;
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_posts();

                this.loading = false;
            },
        },
    };
</script>
