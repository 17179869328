const wp = window.wp;
const el = wp.element.createElement;

// const InspectorControls = wp.blockEditor.InspectorControls;
// const PanelBody = wp.components.PanelBody;

const ButtonGroup = wp.components.ButtonGroup;
const Button = wp.components.Button;
// const SelectControl = wp.components.SelectControl;
const RichText = wp.blockEditor.RichText;
const MediaUpload = wp.blockEditor.MediaUpload;
// const TabPanel = wp.components.TabPanel;

const media_upload = (answer, onSelect) =>
{
    let html = [];

    if (answer.image)
    {
        html.push(el('img', {
            src: answer.image,
            width: 300,
            className: 'mt-2 mb-2',
            style: { display: 'block', maxHeight: '300px' },
        }));
        html.push(el('a', {
            href: '#',
            style: { display: 'block' },
            onClick: () =>
            {
                if (confirm('Are you sure you want to remove the image?'))
                {
                    onSelect({ url: null });
                }
            },
        }, 'Remove image'));
    }

    html.push(el(MediaUpload, {
        title: 'Image Upload',
        allowedTypes: ['image'],
        className: 'mt-2 mb-4',
        value: answer.image,
        onSelect: onSelect,
    }));

    return el('div', { className: 'mt-2 mb-2' }, html);
};

export const CheckQuizBlock = {
    title: 'Check Quiz Block',
    icon: 'forms',
    category: 'map',
    attributes:
    {
        items:
        {
            type: 'array',
            default: [
                {
                    text: '',
                    validated: false,
                    answers: [],
                },
            ],
        },
        current:
        {
            type: 'number',
            default: 0,
        },
    },
    edit: function (props)
    {
        const change_answer_attr = (prop, q_index, a_index, value) =>
        {
            const items = [ ...props.attributes.items ];

            items[q_index].answers[a_index][prop] = value;

            props.setAttributes({ items });
        };

        const change_question_attr = (prop, q_index, value) =>
        {
            const items = [ ...props.attributes.items ];

            items[q_index][prop] = value;

            props.setAttributes({ items });
        };

        let tabs = props.attributes.items.map(( item, index, ) =>
        {
            return el(Button, {
                variant: 'primary',
                className: (props.attributes.current === index ? 'is-primary' : ''),
                style: { color: (props.attributes.current === index ? '#fff' : ''), backgroundColor: (props.attributes.current === index ? '#ea345799' : '') },
                isDefault: true,
                onClick: () =>
                {
                    props.setAttributes({ current: index });
                },
            }, 'Q' + (index + 1));
        });

        tabs.push(
            el(Button, {
                className: 'is-primary',
                style: { color: '#fff', backgroundColor: '#ea3457' },
                variant: 'primary',
                isDefault: true,
                onClick: () =>
                {
                    const items = [ ...props.attributes.items ];

                    items.push({
                        text: '',
                        validated: false,
                        answers: [],
                    });

                    props.setAttributes({ items: items, current: items.length - 1 });
                },
            }, 'Add question'),
            el(Button, {
                className: 'is-primary',
                style: { color: '#fff', backgroundColor: '#ea3457' },
                variant: 'primary',
                isDefault: true,
                onClick: () =>
                {
                    const items = [ ...props.attributes.items ];

                    items.push({
                        text: '',
                        validated: false,
                        custom_answer: true,
                        answers: [{
                            text: 'Add your own answer',
                            custom: true,
                            correct: false,
                        }],
                    });

                    props.setAttributes({ items: items, current: items.length - 1 });
                },
            }, 'Add custom question'),
            el(Button, {
                className: 'is-primary',
                style: { color: '#fff', backgroundColor: '#ea3457' },
                variant: 'primary',
                isDefault: true,
                onClick: () =>
                {
                    if (confirm('Are you sure you want to remove this question?'))
                    {
                        const items = [ ...props.attributes.items ];
                        const current = props.attributes.current;

                        items.splice(current, 1);

                        props.setAttributes({ items, current: items.length - 1 });
                    }
                },
            }, 'Remove question')
        );

        let items = props.attributes.items.map(( item, q_index ) =>
        {
            let answers = item.answers.map(( answer, a_index ) =>
            {
                return el('div', { className: 'row', style: { paddingLeft: '50px' } },
                    el('div', { className: 'col-12' },
                        el('div', { className: 'form-check' },
                            el('a', {
                                href: '#',
                                style: {
                                    color: '#ea3457',
                                    fontSize: '35px',
                                    textDecoration: 'none',
                                    display: 'inline-flex',
                                    width: '25px',
                                    height: '25px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '10px',
                                    position: 'relative',
                                    top: '8px',
                                },
                                variant: 'primary',
                                isDefault: true,
                                onClick: () =>
                                {
                                    if (confirm('Are you sure you want to remove this answer?'))
                                    {
                                        const items = [ ...props.attributes.items ];

                                        items[q_index].answers.splice(a_index, 1);

                                        props.setAttributes({ items });
                                    }
                                },
                            }, '-'),
                            el('label', { className: 'checkbox_container', style: { display: 'inline-block' } },
                                el('input', {
                                    type: 'checkbox',
                                    onChange: (el) => change_answer_attr('correct', q_index, a_index, el.target.checked),
                                }),
                                el('span', { className: 'checkmark' }, ''),
                            ),
                            el(RichText, {
                                tagName: 'span',
                                value: answer.text,
                                allowedFormats: [],
                                placeholder: 'Answer title',
                                style: { marginLeft: '30px' },
                                disabled: answer.custom,
                                onChange: (content) => change_answer_attr('text', q_index, a_index, content),
                            }),
                            el(RichText, {
                                tagName: 'span',
                                value: answer.explanation,
                                allowedFormats: [],
                                placeholder: 'Answer explanation',
                                style: { marginLeft: '30px', marginTop: '10px', display: 'block', borderTop: '1px solid #eaeaea' },
                                onChange: (content) => change_answer_attr('explanation', q_index, a_index, content),
                            }),
                            media_upload(answer, (image) =>
                            {
                                change_answer_attr('image', q_index, a_index, image.url);
                            }),
                        ),
                    ),
                );
            });

            return el('div', { style: { display: (props.attributes.current === q_index) ? 'block' : 'none' } },
                el('div', { className: 'row pb-4 pt-4', style: { paddingLeft: '30px' } },
                    el('div', { className: 'col-12' },
                        el(RichText, {
                            tagName: 'h4',
                            className: 'font-weight-bolder text-danger',
                            value: item.text,
                            allowedFormats: [],
                            placeholder: 'Question title',
                            onChange: (content) => change_question_attr('text', q_index, content),
                        }),
                    )
                ),
                media_upload(item, (image) =>
                {
                    change_question_attr('image', q_index, image.url);
                }),
                answers,
                el('div', { className: 'row pb-4 pt-4', style: { paddingLeft: '80px' } },
                    el('div', { className: 'col-12' },
                        el(Button, {
                            className: 'is-primary',
                            style: { color: '#fff', backgroundColor: '#ea3457' },
                            variant: 'primary',
                            isDefault: true,
                            onClick: () =>
                            {
                                const items = [ ...props.attributes.items ];

                                items[q_index].answers.push({
                                    text: '',
                                    correct: false,
                                });

                                props.setAttributes({ items: items });
                            },
                        }, 'Add answer')
                    )
                )
            );
        });

        return [
            // el(InspectorControls,
            //     { key: 1 },
            //     el(PanelBody,
            //         { title: 'Attributes' },
            //         el(SelectControl, {
            //             label: 'Type',
            //             options: [
            //                 { label: '2x Quiz', value: '2' },
            //                 { label: '3x Quiz', value: '3' },
            //             ],
            //             onChange: (type) =>
            //             {
            //                 props.setAttributes({ type: type });
            //             },
            //         }),
            //     )
            // ),
            el('div', { className: 'map-check-quiz-block', },
                el('h3', { className: '' }, 'Quiz'),
                el('div', { className: 'row', style: { paddingLeft: '30px' } },
                    el('div', { className: 'col-12' },
                        el(ButtonGroup, { },
                            tabs,
                        ),
                    )
                ),
                el('div', { className: 'row', style: { paddingLeft: '30px' } },
                    el('div', { className: 'col-12' },
                        items,
                    )
                ),
            )
        ];
    },

    save: function (props)
    {
        let items = props.attributes.items;

        return el('div',
            {
                className: 'check-quiz-block',
            },
            JSON.stringify(items)
        );
    },
};

export default CheckQuizBlock;
