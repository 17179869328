import { ApiService } from './api-service';

const RoleService =
{
    all()
    {
        return ApiService.get(`/api/roles`);
    },

    get(id)
    {
        return ApiService.get(`/api/roles/${id}`);
    },

};

export { RoleService };
