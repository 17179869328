<template>
    <a
        :href="link"
        class="map-resource-post"
        :class="{
            'map-no-image': noImage,
            'map-featured': featured,
        }"
        @click.prevent="read_more"
    >
        <div class="map-article-info">
            <div class="map-article-info-inner">
                <span class="map-article-center">Countering Opposition</span>
                <h2>{{ title }}</h2>
            </div>
            <template v-if="!noImage">
                <lazy-img :src="get_image()" v-if="showImage" />
                <div v-else class="map-article-image-placeholder"></div>
            </template>
            <!-- <p>{{ excerpt }}</p> -->
        </div>
    </a>
</template>

<script>
    import LazyImg from '@/components/LazyImg';

    export default
    {
        name: 'resource-post',
        components:
        {
            LazyImg,
        },
        props:
        {
            featured: Boolean,
            noImage: Boolean,
            image: [String, Object],
            title: String,
            excerpt: String,
            link: String,
        },
        data()
        {
            return {
                showImage: true,
            };
        },
        watch: {
            image()
            {
                this.showImage = null;
                setTimeout(() => this.showImage = this.image);
            },
        },
        mounted()
        {
            this.showImage = !!this.image;
        },
        methods:
        {
            read_more()
            {
                this.$router.push(this.link);
            },
            get_image()
            {
                if (this.image && this.image.id)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.image.id + '/400x400_crop';
                }
                else if (this.image)
                {
                    return this.image;
                }

                this.showImage = false;
            },
        },
    }
</script>
