<template>
    <div class="map-todo-list" data-label="To Do List">
        <ul class="map-todo-items">
            <li v-for="(item, key) in items_list" :key="'item_' + key">
                <input v-if="item.editable" type="text" v-model="item.text" />
                <template v-else>{{ item.text }}</template>
                <checkbox :checked="item.checked"></checkbox>
                <button class="map-item-remove" v-on:click="remove_item(key)">x</button>
            </li>
        </ul>
        <button class="map-todo-add-new" v-on:click="add_new">+</button>
    </div>
</template>

<script>
    import Checkbox from  '@/components/Inputs/Checkbox';

    export default
    {
        name: 'todo-list',
        components:
        {
            Checkbox,
        },
        props:
        {
            items: Array,
        },
        data()
        {
            return {
                items_list: [{
                    text: '',
                    checked: false
                }],
            };
        },
        mounted()
        {
            this.items_list = this.items;
        },
        methods:
        {
            add_new()
            {
                this.items_list.push({
                    text: '',
                    checked: false,
                    editable: true,
                });
            },
            remove_item(index)
            {
                if (confirm('Are you sure you want to remove this item?'))
                {
                    this.items_list.splice(index, 1);
                }
            },
        },
    }
</script>
