<template>
    <div class="map-page-two-factor map-center-layout" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <h1 class="map-lineunder">Two Factor Authentication</h1>
            <p>A 5-digit code has been sent to your registered email. Please insert here:</p>
            <loader />
            <form @submit.prevent="auth">
                <base-input
                    type="text"
                    placeholder="Two Factor Code"
                    v-model="model.code"
                    :error="get_errors('code')"
                    >
                </base-input>
                <Button type="submit" class="map-color-primary">AUTHENTICATE</Button>
                <p class="map-login-info">*If you encounter any technical issues, please email us at <a href="mailto:map-support@ippf.org" target="_blank">map-support@ippf.org</a> and we will assist you.</p>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import useVuelidate from '@vuelidate/core';
    import { required, numeric } from '@vuelidate/validators';

    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    export default
    {
        name: 'Login',
        components:
        {
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                response: '',
                error: '',
                model:
                {
                    code: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    code: { required, numeric },
                },
            };
        },
        computed:
        {
            ...mapGetters('Auth',
            [
                'authenticating',
                'error'
            ]),
        },
        methods:
        {
            async auth()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                await this.$store.dispatch('Auth/login_two_factor',
                {
                    code: this.model.code,
                });

                this.loading = false;
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                if (this.error)
                {
                    return this.error;
                }

                return false;
            }
        },
    };
</script>
