const wp = window.wp;
const el = wp.element.createElement;

// const { InnerBlocks } = wp.blockEditor;
//
// const BLOCKS_TEMPLATE = [
//     ['core/video'],
//     ['core/heading', { level: 2, placeholder: 'Video Title' }],
//     ['core/paragraph', { placeholder: 'Video Description' }],
// ];

const RichText = wp.blockEditor.RichText;
const MediaUpload = wp.blockEditor.MediaUpload;
// const TabPanel = wp.components.TabPanel;

const media_upload = (media, onSelect) =>
{
    let html = [];

    if (media.url)
    {
        html.push(el('video', {
            src: media.url,
            width: 300,
            style: { display: 'block', maxHeight: '300px' },
        }));
        html.push(el('a', {
            href: '#',
            style: { display: 'block' },
            onClick: () =>
            {
                if (confirm('Are you sure you want to remove the video?'))
                {
                    onSelect({ url: null });
                }
            },
        }, 'Remove video'));
    }

    html.push(el(MediaUpload, {
        title: 'Video Upload',
        allowedTypes: ['video'],
        value: media.url,
        onSelect: onSelect,
    }));

    return el('div', { }, html);
};

export const VideoBlock = {
    title: 'MAP Video Block',
    icon: 'format-video',
    category: 'map',
    attributes: {
        url:
        {
            type: 'string',
            default: '',
        },
        title:
        {
            type: 'string',
            default: '',
        },
        summary:
        {
            type: 'string',
            default: '',
        },
        created_at:
        {
            type: 'string',
            default: '',
        },
    },
    edit: (props) =>
    {
        const attrs = props.attributes;

        return el('div', { className: 'video-block' },
            media_upload(attrs, (video) =>
            {
                props.setAttributes({ url: video.url, created_at: video.created_at });
            }),
            el(RichText, {
                tagName: 'h2',
                value: attrs.title,
                allowedFormats: [],
                placeholder: 'Video title',
                onChange: (content) => props.setAttributes({ title: content }),
            }),
            el(RichText, {
                tagName: 'span',
                value: attrs.summary,
                allowedFormats: [],
                placeholder: 'Video summary',
                onChange: (content) => props.setAttributes({ summary: content }),
            }),
        );
    },

    save: function (props)
    {
        return el('div',
            {
                className: 'video-block',
            },
            JSON.stringify(props.attributes)
        );
    },
};

export default VideoBlock;
