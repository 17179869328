<template>
    <a
        :href="link"
        class="map-lesson"
        :data-status="progress || 'start'"
        :class="{
            'map-featured': featured,
            'map-has-image': show_image,
        }"
        @click.prevent="read_more"
    >
        <lazy-img :src="get_image()" v-if="show_image" />
        <div class="map-lesson-info">
            <h2>{{ title }}</h2>
            <p>{{ excerpt }}</p>
        </div>
    </a>
</template>

<script>
    import LazyImg from '@/components/LazyImg';

    export default
    {
        name: 'lesson',
        components:
        {
            LazyImg,
        },
        props:
        {
            featured: Boolean,
            title: String,
            excerpt: String,
            image: [String, Object],
            progress: String,
            link: String,
        },
        data()
        {
            return {
                show_image: false,
            };
        },
        watch: {
            image()
            {
                this.show_image = null;
                setTimeout(() => this.show_image = this.image);
            },
        },
        mounted()
        {
            this.show_image = !!this.image;
        },
        methods:
        {
            read_more()
            {
                this.$router.push(this.link);
            },
            get_image()
            {
                if (this.image && this.image.id)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.image.id;
                }
                else if (this.image)
                {
                    return this.image;
                }
            },
        },
    }
</script>
