import axios from 'axios';
import { TokenService } from './token-service';

const ApiService =
{
    init(url)
    {
        axios.defaults.baseURL = url;
        axios.defaults.withCredentials = true;
        
        axios.interceptors.response.use((response) =>
        {
            return response;
        }, (error) =>
        {
            if (error)
            {
                const origina_req = error.config;

                if (error.response.status === 401 && !origina_req._retry)
                {
                    origina_req._retry = true;
                    TokenService.remove_token();
                    TokenService.remove_two_factor_token();
                    this.clear_header();
                }
            }

            return Promise.reject(error.response);
        });
    },

    set_header()
    {
        axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.get_token()}`;
    },

    clear_header()
    {
        axios.defaults.headers.common = {};
    },

    get(resource, params)
    {
        return axios.get(resource, {
            params: params || {}
        });
    },

    post(resource, data)
    {
        return axios.post(resource, data);
    },

    put(resource, data)
    {
        if (data instanceof FormData)
        {
            data.append('_method', 'put');

            return axios.post(resource, data);
        }
        else
        {
            return axios.put(resource, data);
        }
    },

    delete(resource)
    {
        return axios.delete(resource);
    },

    request(data)
    {
        return axios(data);
    },
};

export { ApiService };
