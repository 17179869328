<template>
    <div class="map-page map-page-lesson" :class="lesson_type_class">
        <component
            v-if="!loading && lesson_component !== ''"
            :is="lesson_component"
            :lesson="post"
            :next-lesson="next_lesson"
            >
        </component>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue';

    import { PostService, PostType } from '@/services';

    export default
    {
        components:
        {
            LessonVideoPresentation: defineAsyncComponent(() =>
            {
                return import('./Lessons/LessonVideoPresentation.vue')
            }),
            LessonLongFormArticle: defineAsyncComponent(() =>
            {
                return import('./Lessons/LessonLongFormArticle.vue')
            }),
        },
        props:
        {
            data: Object,
        },
        setup()
        {
            return {
                PostType: PostType,
            };
        },
        data()
        {
            return {
                loading: false,
                lesson_component: '',
                lesson_type_class: '',
                lesson_progress: 0,
                next_lesson: {},
                post: {},
            };
        },
        async beforeRouteUpdate(to)
        {
            // if (window.location.hash === '#' || window.location.hash === '')
            // {
            //     document.querySelector('#app > .ps').scrollTo(0, 0, 'smooth');
            // }

            if (this.data)
            {
                this.post = this.data;
            }
            else
            {
                await this.get_post(to.params.id);
            }
        },
        async mounted()
        {
            if (this.data)
            {
                this.post = this.data;
                await this.prepare_post_data();
                // await this.get_post(27);
            }
            else
            {
                await this.get_post(this.$route.params.id);
            }
        },
        methods:
        {
            async get_post(id)
            {
                this.loading = true;

                this.post = (await PostService.get(PostType.LESSON, id)).data;

                await this.prepare_post_data();

                this.loading = false;
            },

            async prepare_post_data()
            {
                const id = this.post.id;
                
                this.post.lessons = this.post.lessons.filter((l) => l.status === 'ACTIVE');

                if (this.post.lessons.length > 1)
                {
                    const next_index = this.post.lessons.findIndex((item) => item.id == id) + 1;

                    if (next_index < this.post.lessons.length)
                    {
                        this.next_lesson = this.post.lessons[next_index];
                    }
                    else
                    {
                        this.next_lesson = this.post.lessons[0];
                    }
                }

                switch (this.post.elearning.type)
                {
                    case 'LONG FORM ARTICLES':
                        this.lesson_component = 'LessonLongFormArticle';
                        this.lesson_type_class = 'map-page-lesson-long-form';
                        break;

                    case 'VIDEO & PRESENTATION':
                        this.lesson_component = 'LessonVideoPresentation';
                        this.lesson_type_class = 'map-page-lesson-video-presentation';
                        break;
                }
            },
        },
    };
</script>
