const Utils = {

    /**
     *  Are we at the bottom of the window?
     *
     *  @return bool
     */
    isAtBottomOfWindow: (el) =>
    {
        return Math.max(el.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + el.innerHeight === document.documentElement.scrollHeight;
    },


    /**
     *  Are we at the bottom of the DOMElement?
     *
     *  @param DOMElement el
     *
     *  @return bool
     */
    isAtBottomOfElement: (el) =>
    {
        return el.offsetHeight + el.scrollTop >= el.scrollHeight;
    },

    /**
     *  Time difference in human readable format.
     *
     *  @param Date d1
     *  @param Date d2
     *
     *  @return String
     */
    timeDiff: (d1, d2) =>
    {
        const seconds = Math.floor((d1 - d2) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1)
        {
            return Math.floor(interval) + ' y ago';
        }

        interval = seconds / 2592000;

        if (interval > 1)
        {
            return Math.floor(interval) + ' mo ago';
        }

        interval = seconds / 86400;

        if (interval > 1)
        {
            return Math.floor(interval) + ' d ago';
        }

        interval = seconds / 3600;

        if (interval > 1)
        {
            return Math.floor(interval) + ' h ago';
        }

        interval = seconds / 60;

        if (interval > 1)
        {
            return Math.floor(interval) + ' m ago';
        }

        return Math.floor(seconds) + ' s ago';
    },

    /**
     *  Slugify a string.
     *
     *  @param String text
     *
     *  @return String
     */
    slugify: (text) =>
    {
        if (!text)
        {
            return text;
        }

        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w-]+/g, '')       // Remove all non-word chars
            .replace(/--+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    },

    /**
     *  Generate unique id.
     *
     *  @return String
     */
    generate_unique: () =>
    {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    },
};

export { Utils };
