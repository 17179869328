const wp = window.wp;
const el = wp.element.createElement;

const Button = wp.components.Button;
// const RichText = wp.blockEditor.RichText;

export const ToDoBlock = {
    title: 'To Do Block',
    icon: 'forms',
    category: 'map',
    attributes:
    {
        items:
        {
            type: 'array',
            default: [
                {
                    text: '',
                    checked: false,
                    editable: false,
                },
            ],
        },
    },
    edit: function (props)
    {
        const change_attr = (prop, index, value) =>
        {
            const items = [ ...props.attributes.items ];

            items[index][prop] = value;

            props.setAttributes({ items });
        };

        let items = props.attributes.items.map(( item, index, ) =>
        {
            return el('li', {},
                el('input', {
                    type: 'text',
                    value: item.text,
                    onChange: (ev) => change_attr('text', index, ev.target.value),
                }),
                el(Button, {
                    className: 'map-item-remove',
                    onClick: () =>
                    {
                        if (confirm('Are you sure you want to remove this item?'))
                        {
                            const items = [ ...props.attributes.items ];

                            items.splice(index, 1);

                            props.setAttributes({ items });
                        }
                    },
                }, 'x'),
            );
        });

        return [
            el('div', { className: 'map-todo-list', dataLabel: 'To Do List' },
                el('ul', { className: 'map-todo-items' },
                    items,
                ),
                el(Button, {
                    className: 'map-todo-add-new',
                    onClick: () =>
                    {
                        const items = [ ...props.attributes.items ];

                        items.push({
                            text: '',
                            checked: false,
                            editable: false,
                        });

                        props.setAttributes({ items: items });
                    },
                }, '+'),
            )
        ];
    },

    save: function (props)
    {
        let items = props.attributes.items;

        return el('div',
            {
                className: 'todo-block',
            },
            JSON.stringify(items)
        );
    },
};

export default ToDoBlock;
