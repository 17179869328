<template>
    <div class="map-page map-page-case-studies" :class="{'map-loading': loading }">
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Case Studies</h1>
            <p class="map-page-subtitle">LIVE REPOSITORY PER COUNTRY</p>
            <basic-dropdown v-model="show_by" :options="filters" placeholder="Explore our case studiess by:"></basic-dropdown>
            <dropdown
                v-if="show_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :closeOnSelect="false"
                :options="countries"
                @click="filter_select_country"
                >
            </dropdown>
            <div class="map-case-studies-sections">
                <loader />
                <div class="map-case-studies-section" data-section="country" v-if="show_by === 'Country' && !show_posts">
                    <mv-map @click="modal_select_country" v-model="modal_country" :countries="countries"></mv-map>
                </div>
                <div class="map-case-studies-section" data-section="topic" v-if="show_by === 'Language' && !show_posts">
                    <ul class="map-topics">
                        <li v-for="(topic, key) in topics" :key="'topic_' + key" @click="select_topic(topic)">{{ topic }}</li>
                    </ul>
                </div>
                <div class="map-case-studies-section" data-section="tag" v-if="show_by === 'Tag' && !show_posts">
                    <ul class="map-topics">
                        <li v-for="(tag, key) in tags" :key="'tag_' + key" @click="select_tag(tag)">{{ tag }}</li>
                    </ul>
                </div>
                <div class="map-case-studies-section" data-section="case-studies" v-if="show_posts">
                    <a class="map-back-button" @click="show_posts = false">BACK</a>
                    <articles :style="'resource'">
                        <p v-if="!posts || !posts.length">There are no case-studies</p>
                        <resource-post
                            v-for="(case_study, key) in posts"
                            :key="key"
                            :featured="key === 0"
                            :image="case_study.cover_image"
                            :title="case_study.title"
                            :excerpt="case_study.summary"
                            :link="'/case-study/' + case_study.id"
                            >
                        </resource-post>
                    </articles>
                    <button class="map-button map-load-more" v-if="has_more_pages" @click.prevent="load_more">LOAD MORE</button>
                </div>
            </div>
            <div class="map-center-access">
                <p>You have access to case studies from:</p>
                <router-link
                    :to="{ name: center }"
                    v-for="(center, key) in user.centers"
                    :key="key"
                    >
                    {{ center }}
                </router-link>
            </div>
        </div>
        <perfect-scrollbar :options="{ suppressScrollX: true }" class="map-case-studies-detail" :class="{ 'map-visible': show_details }">
            <template v-if="modal_country">
                <span class="map-close" @click="close_details()">x</span>
                <h1 class=""><flag :iso="modal_country.code.toLowerCase()"></flag>{{ modal_country.name }}</h1>
                <p>{{ modal_country.content }}</p>
                <articles label="Case Studies" :style="'resource'" v-if="case_studies && case_studies.length">
                    <post
                        v-for="(case_study, key) in case_studies"
                        :key="key"
                        :featured="key === 0"
                        :image="case_study.cover_image"
                        :title="case_study.title"
                        :excerpt="case_study.summary"
                        :link="'/case-study/' + case_study.id"
                        >
                    </post>
                </articles>
                <articles label="Resources" :style="'resource'" v-if="resources && resources.length">
                    <post
                        v-for="(case_study, key) in resources"
                        :key="key"
                        :featured="key === 0"
                        :image="case_study.cover_image"
                        :title="case_study.title"
                        :excerpt="case_study.summary"
                        :link="'/resource/' + case_study.id"
                        >
                    </post>
                </articles>
            </template>
        </perfect-scrollbar>
        <map-sidebar class="map-sidebar-case-studies">
            <!-- <basic-dropdown v-model="show_by" :options="filters" placeholder="Explore our knowledge base by:"></basic-dropdown>
            <dropdown
                v-if="show_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :closeOnSelect="true"
                :options="countries"
                @click="filter_select_country"
                >
            </dropdown> -->
        </map-sidebar>
    </div>
</template>

<script>
    import Dropdown from '@/components/Inputs/Dropdown';
    import BasicDropdown from '@/components/Inputs/BasicDropdown';
    import Articles from '@/components/Articles/Articles';
    import ResourcePost from '@/components/Articles/ResourcePost';
    import Post from '@/components/Articles/Post';
    import MvMap from '@/components/MvMap';
    import MapSidebar from '@/components/MapSidebar';
    import Loader from '@/components/Loader';

    import { PostService, PostType, CountryService, TopicService, TagService } from '@/services';

    export default
    {
        name: 'CaseStudies',
        components:
        {
            Dropdown,
            BasicDropdown,
            Articles,
            ResourcePost,
            Post,
            MvMap,
            MapSidebar,
            Loader,
        },
        data()
        {
            return {
                show_details: false,
                show_posts: false,
                show_by: 'Country',
                loading: true,
                has_more_pages: false,
                per_page: 12,
                last_page: 1,
                page: 1,
                posts:[],
                resources: [],
                case_studies: [],
                topics: [],
                tags: [],
                countries: [],
                topic: '',
                tag: '',
                modal_country: '',
                country: '',
                filter_by: '',
                filters: [
                    'Country',
                    'Language',
                    'Tag',
                ],
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            this.loading = true;
            await this.get_posts();

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all({ with: PostType.CASE_STUDY })).data;
            this.tags = (await TagService.all({ with: PostType.CASE_STUDY })).data;

            this.countries = this.countries.filter((item) => item.visible_in_case_study);

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.loading = false;
        },
        methods:
        {
            async get_posts()
            {
                const response = (await PostService.all(PostType.CASE_STUDY, {
                    page: this.page,
                    topic: this.topic,
                    tag: this.tag,
                    exclude: 'content',
                    'per-page': this.per_page,
                    'order-by': 'created_at',
                })).data;
                const posts = response.data;

                if (this.page === 1)
                {
                    this.posts = posts;
                }
                else
                {
                    this.posts.push(...posts);
                }

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async get_case_studies()
            {
                const response = (await PostService.all(PostType.CASE_STUDY, {
                    topic: this.topic,
                    tag: this.tag,
                    country: this.country,
                    'per-page': 3,
                    'order-by': 'published_at',
                })).data;

                this.case_studies = response.data;
            },

            async get_resources()
            {
                const response = (await PostService.all(PostType.RESOURCE, {
                    topic: this.topic,
                    tag: this.tag,
                    country: this.country,
                    'per-page': 3,
                    'order-by': 'published_at',
                })).data;

                this.resources = response.data;
            },

            async load_more()
            {
                this.loading = true;

                await this.get_posts();

                this.loading = false;
            },

            async select_topic(topic)
            {
                this.reset_filters();

                this.topic = topic;
                this.show_posts = true;

                await this.apply_filters();
            },

            async select_tag(tag)
            {
                this.reset_filters();

                this.tag = tag;
                this.show_posts = true;

                await this.apply_filters();
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_posts();

                this.loading = false;
            },

            modal_select_country()
            {
                if (this.modal_country)
                {
                    this.country = this.modal_country.id;

                    this.get_case_studies();
                    this.get_resources();

                    this.show_details = true;
                }
            },

            filter_select_country()
            {
                if (!this.country)
                {
                    return ;
                }

                if (!this.modal_country || (this.modal_country.id !== this.country && this.country != ''))
                {
                    this.modal_country = this.countries.filter((i) => i.id == this.country).pop();
                    this.show_details = true;
                }
            },

            close_details()
            {
                this.country = '';
                this.show_details = false
            },

            reset_filters()
            {
                this.topic = '';
                this.tag = '';
            },
        },
    };
</script>
