// import { Utils } from '@/helpers';

const el = window.wp.element.createElement;
const InnerBlocks = window.wp.blockEditor.InnerBlocks;

const ALLOWED_BLOCKS = [ 'blocks/accordion-section-block' ];

export const AccordionBlock = {
    title: 'Accordion Block',
    icon: 'align-wide',
    category: 'map',

    edit: function ()
    {
        return el(
            'div',
            { className: 'map-block-accordion' },
            el(InnerBlocks, {
                allowedBlocks: ALLOWED_BLOCKS,
            }),
        );
    },

    save: function ()
    {
        // const id = Utils.generate_unique();

        return el(
            'div',
            { className: 'accordion-block' },
            el(InnerBlocks.Content),
        );
    },
};

export default AccordionBlock;
