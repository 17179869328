import { ApiService } from './api-service';

const ShareService =
{
    get(token)
    {
        return ApiService.get(`/api/share/${token}/show-link`);
    },

    get_by_post(post_id, post_type)
    {
        return ApiService.get(`/api/share/${post_id}/${post_type}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/share/${id}`, data);
    },

    generate_public_link(post_id, post_type)
    {
        return ApiService.put(`/api/share/${post_id}/${post_type}/generate-public-token`);
    },

    share(post_id, post_type, params)
    {
        return ApiService.put(`/api/share/${post_id}/${post_type}/share`, params);
    },
};

export { ShareService };
