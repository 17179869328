<template>
    <div class="map-questions-quiz">
        <h1 class="map-lineunder">{{ title }}</h1>
        <div v-if="!has_questions" class="map-questions-quiz-empty">
            <img src="@/assets/images/stars.svg" />
            <h2>For now, there are no questions here.</h2>
        </div>
        <div v-else>
            <div v-if="!quiz_done" class="map-questions-quiz-questions">
                <p>You can choose multiple answers</p>
                <p>Question {{ current_index + 1 }} out of {{ items.length }}</p>
                <h2>{{ items[current_index].text }}</h2>
                <checkbox
                    v-for="(answer, key) in items[current_index].answers"
                    :key="'answer_' + key"
                    v-model:checked="answer.response"
                >
                    <span
                        :class="{
                            'map-correct-answer': (items[current_index].validated && answer.correct),
                            'map-wrong-answer': (items[current_index].validated && !answer.correct)
                        }"
                    >
                        {{ answer.text }}
                        <textarea placeholder="Answer..." v-if="answer.custom"></textarea>
                    </span>
                    <i v-if="items[current_index].validated" style="display: block">{{ answer.explanation }}</i>
                </checkbox>
            </div>
            <div v-else class="map-questions-quiz-done">
                <img src="@/assets/images/quiz-cup.svg" />
                <p><b>Congrats,</b><br />you have answered <b>{{ quiz_score }}</b> out of <b>{{ items.length }}</b> items.</p>
            </div>
            <footer>
                <button v-if="quiz_done" class="map-button map-color-primary" @click="reset_quiz">START AGAIN</button>
                <template v-else>
                    <button class="map-button map-color-gray" :disabled="current_index <= 0" @click="get_previous">PREVIOUS</button>
                    <button v-if="!items[current_index].validated" @click="validate_answer" class="map-button map-color-primary">VALIDATE</button>
                    <button v-else class="map-button map-color-primary" @click="get_next">NEXT</button>
                </template>
            </footer>
        </div>
    </div>
</template>

<script>
    import Checkbox from  '@/components/Inputs/Checkbox';

    export default
    {
        name: 'questions-quiz',
        components:
        {
            Checkbox,
        },
        props: ['questions'],
        data()
        {
            return {
                title: 'Quiz',
                quiz_score: 0,
                current_index: 0,
                score: 0,
                items: this.questions,
            };
        },
        computed:
        {
            has_questions()
            {
                if (!this.questions || !this.items.length)
                {
                    return false;
                }

                if (this.items[0].text === '')
                {
                    return false;
                }

                return true;

                // return this.questions && this.items.length !== 0;
            },
            quiz_done()
            {
                return !(this.current_index < this.items.length);
            },
            correct_answers()
            {
                return this.items[this.current_index].answers.filter(a => (a.correct)).length;
            },
        },
        mounted()
        {
            this.items.forEach((question) =>
            {
                if (question.custom_answer)
                {
                    question.answers.push(question.answers.shift());
                }
            });
        },
        methods:
        {
            validate_answer()
            {
                for (let i = 0; i < this.items[this.current_index].answers.length; i++)
                {
                    if (this.items[this.current_index].answers[i].response)
                    {
                        this.items[this.current_index].validated = true;
                        return ;
                    }
                }
            },

            get_previous()
            {
                this.current_index = Math.max(0, this.current_index - 1);
            },

            get_next()
            {
                if (this.items[this.current_index].validated)
                {
                    this.current_index = Math.min(this.current_index + 1, this.items.length);

                    if (this.current_index > this.items.length - 1)
                    {
                        this.compute_score();
                    }
                }
            },

            compute_score()
            {
                this.items.forEach((question) =>
                {
                    if (question.answers.every(a => (a.response && a.correct || !a.response && !a.correct)))
                    {
                        this.quiz_score++;
                    }
                });
            },

            reset_quiz()
            {
                this.quiz_score = 0;
                this.current_index = 0;
                this.items.forEach((question) =>
                {
                    question.validated = false;
                    question.answers.forEach((answer) => delete answer.response );
                });
            },
        }
    }
</script>
