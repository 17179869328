<template>
    <a
        :href="link"
        class="map-article"
        :class="{
            'map-no-image': noImage,
            'map-featured': featured,
        }"
        @click.prevent="read_more"
    >
        <template v-if="!noImage">
            <lazy-img :src="get_image()" v-if="showImage" />
            <div v-else class="map-article-image-placeholder"></div>
        </template>
        <div class="map-article-info">
            <h2>{{ title }}</h2>
            <span class="map-article-date">{{ date }}</span>
            <!-- <p>{{ excerpt }}</p> -->
            <button class="map-read-more map-button map-color-primary" @click="read_more">READ MORE</button>
        </div>
    </a>
</template>

<script>
    import LazyImg from '@/components/LazyImg';

    export default
    {
        name: 'post',
        components:
        {
            LazyImg,
        },
        props:
        {
            featured: Boolean,
            noImage: Boolean,
            image: [String, Object],
            title: String,
            date: String,
            excerpt: String,
            briefing: String,
            link: String,
        },
        data()
        {
            return {
                showImage: true,
            };
        },
        watch: {
            image()
            {
                this.showImage = null;
                setTimeout(() => this.showImage = this.image);
            },
        },
        methods:
        {
            read_more()
            {
                this.$router.push(this.link);
            },
            get_image()
            {
                if (this.image && this.image.id)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.image.id + '/620x_';
                }
                else if (this.image)
                {
                    return this.image;
                }

                this.showImage = false;
            }
        },
    }
</script>
