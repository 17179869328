<template>
    <div class="map-page-login map-center-layout" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <h1 class="map-lineunder">Sign In</h1>
            <loader />
            <form @submit.prevent="login">
                <base-input
                    type="email"
                    placeholder="Email"
                    v-model="model.email"
                    >
                </base-input>
                <base-input
                    type="password"
                    placeholder="Password"
                    :error="error"
                    v-model="model.password"
                    >
                </base-input>
                <Button type="submit" class="map-color-primary">LOGIN</Button>
                <router-link to="/user/recover" class="icph-label-button">Recover password / account</router-link>
                <span>&nbsp;|&nbsp;</span>
                <a href="mailto:support_movementaccelerator@ippf.org" target="_blank">Contact Us</a>

                <p>Not yet registered? <router-link to="/user/signup" class="icph-label-button">Register here.</router-link></p>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    export default
    {
        name: 'Login',
        components:
        {
            BaseInput,
            Button,
            Loader,
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                model:
                {
                    email: '',
                    password: '',
                },
            };
        },
        computed:
        {
            ...mapGetters('Auth',
            [
                'authenticating',
                'error'
            ]),
        },
        methods:
        {
            async login()
            {
                this.loading = true;

                await this.$store.dispatch('Auth/login',
                {
                    email: this.model.email,
                    password: this.model.password,
                });

                this.loading = false;
            },
        },
    };
</script>
