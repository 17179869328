<template>
    <div
        class="map-comments"
        :class="{
            'map-loading': loading,
        }"
        :data-label="label">
        <Loader />
        <base-textarea
            v-model="model.new"
            :error="errors.new"
            rows="3"
            placeholder="Write a response..."
            >
        </base-textarea>
        <button class="map-button map-color-primary map-publish" v-on:click="save()">PUBLISH</button>
        <ul class="map-comments-list">
            <template
                v-for="(comment, key) in comms"
                :key="key"
            >
                <li
                    class="map-comment"
                    :class="{'map-comment-reply': comment.reply}"
                >
                    <div class="map-comment-author">
                        <profile-picture :src="get_author_picture(comment)"></profile-picture>
                        <p>{{ comment.author.name }}</p>
                    </div>
                    <span class="map-comment-date">{{ get_comment_date(comment) }}</span>
                    <p>{{ comment.comment }}</p>
                    <button v-if="!comment.reply" v-on:click="comment._replying = true" class="map-button map-reply">REPLY</button>
                </li>
                <li v-if="comment._replying" class="map-comment-replying">
                    <base-textarea
                        v-model="model.reply"
                        :error="errors.reply"
                        rows="3"
                        placeholder="Write a response..."
                        >
                    </base-textarea>
                    <div class="map-reply-footer">
                        <button class="map-button map-cancel" v-on:click="cancel_reply(comment)">CANCEL</button>
                        <button class="map-button map-color-primary map-publish" v-on:click="save(comment)">PUBLISH</button>
                    </div>
                </li>
                <li
                    v-for="(comment, key) in comment.replys"
                    class="map-comment map-comment-reply"
                    :key="'reply_' + key"
                >
                    <div class="map-comment-author">
                        <profile-picture :src="get_author_picture(comment)"></profile-picture>
                        <p>{{ comment.author.name }}</p>
                    </div>
                    <span class="map-comment-date">{{ get_comment_date(comment) }}</span>
                    <p>{{ comment.comment }}</p>
                    <button v-if="!comment.reply" v-on:click="comment._replying = true" class="map-button map-reply">REPLY</button>
                </li>
            </template>
        </ul>
        <!-- <button class="map-button map-load-more">LOAD MORE</button> -->
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import ProfilePicture from '@/components/ProfilePicture';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';

    import { CommentService } from '@/services';

    import { Utils } from  '@/helpers';

    export default
    {
        name: 'comments',
        components:
        {
            Loader,
            ProfilePicture,
            BaseTextarea,
        },
        props:
        {
            loading: Boolean,
            label: String,
            post_type: String,
            post_id: Number,
            comments:
            {
                type: Array,
                required: false,
            },
        },
        data()
        {
            return {
                comms: [],
                errors:
                {
                    new: '',
                    reply: '',
                },
                model:
                {
                    new: '',
                    reply: '',
                },
            };
        },
        created()
        {
            this.comms = this.comments.map((item) =>
            {
                return item;
            });
        },
        computed:
        {

        },
        methods:
        {
            async save(comment)
            {
                const is_valid = this.validate(comment);

                if (!is_valid)
                {
                    return ;
                }

                if (!comment)
                {
                    CommentService.create({ message: this.model.new, post_type: this.post_type, post_id: this.post_id }).then((response) =>
                    {
                        this.$toast.success('Comment was successfully created!');
                        this.comms.unshift(response.data);
                        this.model.new = '';
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
                else
                {
                    CommentService.reply(comment.id, { message: this.model.reply, post_type: this.post_type, post_id: this.post_id }).then((response) =>
                    {
                        this.$toast.success('Comment was successfully created!');

                        if (!Array.isArray(comment.replys))
                        {
                            comment.replys = [];
                        }

                        comment.replys.unshift(response.data);
                        comment._replying = false;
                        this.model.reply = '';
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },
            cancel_reply(comment)
            {
                comment._replying = false;
                this.model.reply = '';
            },
            validate(comment)
            {
                let value = '';

                this.errors.new = '';
                this.errors.reply = '';

                if (!comment)
                {
                    value = this.model.new.trim();

                    if (value.length < 3)
                    {
                        this.errors.new = 'Message is required';
                    }
                }
                else
                {
                    value = this.model.reply.trim();

                    if (value.length < 3)
                    {
                        this.errors.reply = 'Message is required';
                    }
                }

                return value.length > 3;
            },
            get_author_picture(comment)
            {
                if (comment.author && comment.author.image)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + comment.author.image.id;
                }
            },
            get_comment_date(comment)
            {
                return Utils.timeDiff(new Date(), new Date(comment.created_at));
            },
            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    }
</script>
