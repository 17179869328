<template>
    <div class="map-page map-page-dashboard">
        <div class="map-page-content map-center-layout">
            <h1 class="map-lineunder">Dashboard</h1>
            <div class="map-centers-nav">
                <!-- <div class="map-center-nav map-center-winning-narratives">
                    <router-link :to="{ name: 'Winning Narratives' }" class="map-button map-color-primary map-outline">Winning Narratives</router-link>
                </div>
                <div class="map-center-nav map-center-countering-opposition">
                    <router-link :to="{ name: 'Countering Opposition' }" class="map-button map-color-primary map-outline">Countering Opposition</router-link>
                </div>
                <div class="map-center-nav map-center-social-movement">
                    <router-link :to="{ name: 'Social Movement' }" class="map-button map-color-primary map-outline">Social Movement</router-link>
                </div> -->
            </div>
            <h3>Our Mission</h3>
            <h1 class="map-inner-heading">Together to drive progressive change.</h1>
            <p>In recent years, we have witnessed a concerted attack on abortion, contraception, gender equality, LGBTIQ+ rights and comprehensive sexuality education, as populist governments and socially conservative groups attempt to roll back hard-won gains in sexual and reproductive rights. This so-called ‘anti-gender’ movement, which is well-organized and well-funded, is gaining ground at national, regional and global levels.</p>
            <button class="map-read-more map-button map-color-primary" @click="read_more">READ MORE</button>
        </div>
        <map-sidebar v-if="blog_enabled">
            <articles v-if="blog_enabled" label="Blog" :slider="true">
                <post
                    v-for="(blog, key) in blog_posts"
                    :key="key"
                    :featured="key === 0"
                    :image="blog.cover_image"
                    :title="blog.title"
                    :date="blog.created_at"
                    :excerpt="blog.summary"
                    :link="'/blog/' + blog.id"
                    >
                </post>
            </articles>
        </map-sidebar>
    </div>
</template>

<script>
    import Articles from '@/components/Articles/Articles';
    import Post from '@/components/Articles/Post';
    import MapSidebar from '@/components/MapSidebar';

    import { PostService, PostType } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            Articles,
            Post,
            MapSidebar,
        },
        data()
        {
            return {
                loading: false,
                blog_enabled: false,
                blog_posts: [],
            };
        },
        async mounted()
        {
            this.loading = true;

            await this.get_posts();

            this.loading = false;
        },
        methods:
        {
            async get_posts()
            {
                const response = (await PostService.all(PostType.BLOG, {
                    page: this.page,
                    exclude: 'content',
                    'per-page': 3,
                    'order-by': 'featured,published_at',
                })).data;

                this.blog_posts = response.data;
            },

            read_more()
            {
                this.$router.push({ name: 'About' });
            },
        }
    };
</script>
