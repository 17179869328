import { ApiService } from './api-service';

const PostType =
{
    BLOG: 'blogs',
    NEWS: 'news',
    EVENT: 'events',
    E_LEARNING: 'e-learnings',
    LESSON: 'lessons',
    RESOURCE: 'resources',
    CASE_STUDY: 'case-studies',
};

const PostModel =
{
    'Blog': 'blogs',
    'NEWS': 'news',
    'Event': 'events',
    'Elearning': 'e-learnings',
    'Lesson': 'lessons',
    'Resource': 'resources',
    'CaseStudy': 'case-studies',
};

const PostService =
{
    all(type, params)
    {
        return ApiService.get(`/api/${type}`, params);
    },

    get(type, id, params)
    {
        return ApiService.get(`/api/${type}/${id}`, params);
    },

    search(term, params)
    {
        return ApiService.get(`/api/search/posts/${term}`, params);
    },

    update(type, id, data)
    {
        return ApiService.put(`/api/${type}/${id}`, data);
    },

    publish(type, id)
    {
        return ApiService.put(`/api/${type}/${id}/publish`);
    },

    unpublish(type, id)
    {
        return ApiService.put(`/api/${type}/${id}/unpublish`);
    },

    rate(type, id, params)
    {
        return ApiService.put(`/api/${type}/${id}/rate`, params);
    },

    favorite(type, id)
    {
        return ApiService.put(`/api/${type}/${id}/favorite`);
    },

    done(id)
    {
        return ApiService.put(`/api/lessons/${id}/done`);
    },

    create(type, data)
    {
        return ApiService.post(`/api/${type}`, data);
    },

    remove(type, id)
    {
        return ApiService.delete(`/api/${type}/${id}`);
    },
};

export { PostService, PostType, PostModel };
