<template>
    <div class="main-layout share-layout" :class="route_class">
        <div class="share-layout-container">
            <perfect-scrollbar :options="{ suppressScrollX: true }">
                <div class="map-layout-content">
                    <router-view v-slot="{ Component }">
                        <transition name="route" mode="out-in">
                            <component :is="Component"></component>
                        </transition>
                    </router-view>
                    
                </div>
            </perfect-scrollbar>
            <span class="map-share-join-tag"><b>Join</b> the Movement Accelerator</span>
        </div>
        <button class="map-scroll-to-top" @click="scroll_to_top" :class="{ 'map-visible': show_scroll_to_top }">^</button>
    </div>
</template>

<script>
    export default
    {
        name: 'ShareLayout',
        components:
        {
        },
        data()
        {
            return {
                header_sticky: false,
                show_scroll_to_top: false,
            };
        },
        mounted()
        {
            document.querySelector('#app .share-layout-container > .ps').onscroll = ((ev) =>
            {
                this.show_scroll_to_top = (ev.target.scrollTop > 300);
                this.header_sticky = (ev.target.scrollTop > 50);
            });
        },
        computed:
        {
            route_class()
            {
                const additional_layout_class = this.$route.meta.layout_class || '';

                return 'map-route-' + this.$route.name.toLowerCase().replace(/\s/, '-') + ' ' + additional_layout_class;
            },
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        watch:
        {
            $route()
            {
                this.scroll_to_top();
            },
        },
        methods:
        {
            scroll_to_top()
            {
                document.querySelector('#app .share-layout-container > .ps').scrollTo(0, 0, 'smooth');
            },
        },
    }
</script>
