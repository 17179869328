import { ApiService } from './api-service';

const TopicService =
{
    all(params)
    {
        return ApiService.get('/api/topics', params);
    },

    get(id)
    {
        return ApiService.get(`/api/topics/${id}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/topics/${id}`, data);
    },

    create(data)
    {
        return ApiService.post('/api/topics', data);
    },

    remove(id)
    {
        return ApiService.delete(`/api/topics/${id}`);
    },
};

export {TopicService};
