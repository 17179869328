<template>
    <teleport
        :to="target"
        v-if="target" :disabled="!target"
        >
        <aside class="map-sidebar" v-bind="$attrs">
            <perfect-scrollbar :options="{ suppressScrollX: true }">
                <slot></slot>
            </perfect-scrollbar>
        </aside>
    </teleport>
</template>

<script>
    import { ref } from 'vue';

    export default
    {
        inheritAttrs: false,
        name: 'map-sidebar',
        props:
        {
        },
        setup()
        {
            const target = ref(null);

            return {
                target
            };
        },
        mounted()
        {
            const el = document.querySelector('.main-layout');

            if (el)
            {
                this.target = el;
            }
        }
    }
</script>
