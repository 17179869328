<template>
    <div class="map-page map-page-post map-page-blog-post">
        <div class="map-page-content map-center-layout" :data-section-title="post.topic && post.topic[0]">
            <h1 class="map-post-title">{{ post.title }}</h1>
            <p class="map-post-summary">{{ post.summary }}</p>
            <ul class="map-post-tags">
                <li v-for="(tag) in post.tags" :key="tag">#{{ tag }}</li>
            </ul>
            <div class="map-post-author">
                <profile-picture :src="get_author_picture(post)"></profile-picture>
                <p>by {{ get_author_name(post) }}</p>
            </div>
            <div class="map-post-content page-dynamic-content" v-html="post.content"></div>
        </div>
        <map-sidebar class="map-blog-post">
            <articles label="Related News" :slider="true" v-if="posts & posts.length">
                <post
                    v-for="(news, key) in posts"
                    :key="key"
                    :image="news.cover_image"
                    :title="news.title"
                    :date="news.created_at"
                    :excerpt="news.summary"
                    :link="'/news/' + news.id"
                    >
                </post>
            </articles>
        </map-sidebar>
    </div>
</template>

<script>
    import ProfilePicture from '@/components/ProfilePicture';
    import Articles from '@/components/Articles/Articles';
    import Post from '@/components/Articles/Post';
    import MapSidebar from '@/components/MapSidebar';

    import { GuttenbergContent } from '@/plugins/guttenberg/content';

    import { PostService, PostType } from '@/services';

    export default
    {
        name: 'NewsPost',
        components:
        {
            ProfilePicture,
            Articles,
            Post,
            MapSidebar,
        },
        props:
        {
            data: Object,
        },
        setup()
        {
            return {
                PostType: PostType,
            };
        },
        data()
        {
            return {
                loading: false,
                post: {},
                posts: [],
            };
        },
        async mounted()
        {
            this.loading = true;

            if (this.data)
            {
                this.post = this.data;
            }
            else
            {
                await this.get_post(this.$route.params.id);
                await this.get_posts();
            }

            GuttenbergContent.init();

            this.loading = false;
        },
        methods:
        {
            async get_post(id)
            {
                this.loading = true;

                this.post = (await PostService.get(PostType.NEWS, id)).data;

                this.loading = false;
            },

            async get_posts()
            {
                const response = (await PostService.all(PostType.NEWS, {
                    related: this.post.id,
                    'per-page': 3,
                    'order-by': 'created_at',
                })).data;
                const posts = response.data;

                this.posts = posts;
            },

            get_author_picture(post)
            {
                if (post.author && post.author.image)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + post.author.image.id;
                }
            },

            get_author_name(post)
            {
                if (post.author && post.author.name)
                {
                    return `${post.author.name} ${post.author.surname}`;
                }
            },
        },
    };
</script>
