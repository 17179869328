<template>
    <a
        :href="link"
        class="map-event-post"
        :class="{
            'map-no-image': noImage,
            'map-featured': featured,
        }"
        :data-topic="topic"
        @click.prevent="read_more"
    >
        <template v-if="!noImage">
            <lazy-img :src="get_image()" v-if="showImage" />
            <div v-else class="map-article-image-placeholder"></div>
        </template>
        <div class="map-article-info">
            <span class="map-event-info" v-if="!featured">
                <b>{{ get_formated_date() }}</b>
                {{ get_formated_location() }}
            </span>
            <h2>{{ title }}</h2>
            <span class="map-event-info" v-if="featured">
                <b>{{ get_formated_date() }}</b>
                {{ get_formated_location() }}
            </span>
            <p>{{ excerpt }}</p>
        </div>
    </a>
</template>

<script>
    import LazyImg from '@/components/LazyImg';

    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';

    export default
    {
        name: 'event-post',
        components:
        {
            LazyImg,
        },
        props:
        {
            featured: Boolean,
            noImage: Boolean,
            image: [String, Object],
            author: Object,
            title: String,
            topic: String,
            startDate: String,
            endDate: String,
            location: [String, Object],
            excerpt: String,
            link: String,
        },
        data()
        {
            return {
                showImage: true,
            };
        },
        watch: {
            image()
            {
                this.showImage = null;
                setTimeout(() => this.showImage = this.image);
            },
        },
        mounted()
        {
            this.showImage = !!this.image;
        },
        methods:
        {
            read_more()
            {
                this.$router.push(this.link);
            },
            get_image()
            {
                if (this.image && this.image.id)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.image.id + '/400x400_crop';
                }
                else if (this.image)
                {
                    return this.image;
                }

                this.showImage = false;
            },
            get_author_picture()
            {
                if (this.author && this.author.image)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.author.image.id;
                }
            },
            get_formated_date()
            {
                dayjs.extend(customParseFormat);

                const start_date = dayjs(this.startDate, 'DD/MM/YYYY hh:mm A').format('MMMM D, YYYY');
                const end_date = dayjs(this.endDate, 'DD/MM/YYYY hh:mm A').format('MMMM D, YYYY');

                return start_date + (start_date !== end_date ? ' - ' + end_date : '');
            },
            get_formated_location()
            {
                if (typeof this.location === 'string')
                {
                    return this.location;
                }

                return this.location && (this.location.name + ', ' + this.location.code);
            },
        },
    }
</script>
