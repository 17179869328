<template>
    <div class="map-page map-page-events">
        <img src="@/assets/images/mobile/events-header.jpg" class="map-page-header-img" />
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Discover the Latest News</h1>
            <div class="map-events-inner-content">
                <ul class="map-events-filters">
                    <li :class="{ 'map-selected': filter_events === 'upcoming' }" v-on:click="switch_events('upcoming')">UPCOMING EVENTS</li>
                    <li :class="{ 'map-selected': filter_events === 'past' }" v-on:click="switch_events('past')">PAST EVENTS</li>
                </ul>
                <articles :style="'events'" :loading="loading">
                    <p v-if="!posts || !posts.length">There are no events</p>
                    <event-post
                        v-for="(event, key) in posts"
                        :key="key"
                        :featured="event.featured"
                        :topic="topic"
                        :image="event.cover_image"
                        :title="event.title"
                        :author="event.author"
                        :start-date="event.start_at"
                        :end-date="event.end_at"
                        :location="event.location"
                        :excerpt="event.summary"
                        :link="'/event/' + event.id"
                        >
                    </event-post>
                </articles>
            </div>
            <button class="map-button map-load-more" v-if="has_more_pages" @click.prevent="load_more">LOAD MORE</button>
            <div class="map-center-access">
                <p>You have access to events posts from:</p>
                <router-link
                    :to="{ name: center }"
                    v-for="(center, key) in user.centers"
                    :key="key"
                    >
                    {{ center }}
                </router-link>
            </div>
        </div>
        <map-sidebar class="map-sidebar-events"></map-sidebar>
    </div>
</template>

<script>
    import Articles from '@/components/Articles/Articles';
    import EventPost from '@/components/Articles/EventPost';
    import MapSidebar from '@/components/MapSidebar';

    import { PostService, PostType, CountryService, TopicService, TagService } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            Articles,
            EventPost,
            MapSidebar,
        },
        data()
        {
            return {
                loading: true,
                has_more_pages: false,
                per_page: 12,
                last_page: 1,
                page: 1,
                filter_events: 'upcoming',
                posts:[],
                topics: [],
                tags: [],
                countries: [],
                filters: [
                    'Country',
                    'Language',
                    'Tag',
                ],
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            this.loading = true;
            await this.get_posts();

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;
            this.tags = (await TagService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.loading = false;
        },
        methods:
        {
            async get_posts()
            {
                const response = (await PostService.all(PostType.EVENT, {
                    page: this.page,
                    country: this.filters.country,
                    topic: this.filters.topic,
                    tag: this.filters.topic,
                    exclude: 'content',
                    'filter': this.filter_events,
                    'per-page': this.per_page,
                    'order-by': 'featured,created_at',
                })).data;
                const posts = response.data;

                if (this.page === 1)
                {
                    this.posts = posts;
                }
                else
                {
                    this.posts.push(...posts);
                }

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async load_more()
            {
                this.loading = true;

                await this.get_posts();

                this.loading = false;
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_posts();

                this.loading = false;
            },

            async switch_events(filter)
            {
                this.filter_events = filter;

                this.loading = true;
                this.page = 1;

                await this.get_posts();


                setTimeout(() => this.loading = false, 50);
            },
        },
    };
</script>
