import 'laraberg-vue';

import { SectionBlock } from './blocks';
import { MainTakeawayBlock } from './blocks';
import { AccordionBlock } from './blocks';
import { AccordionSectionBlock } from './blocks';
import { QuizBlock } from './blocks';
import { CheckQuizBlock } from './blocks';
import { VideoBlock } from './blocks';
import { ToDoBlock } from './blocks';
import { PDFBlock } from './blocks';
import { JustifyText } from './formats';
import MediaUpload from './extends/media-upload';
// import MediaPlaceholder from './extends/media-placeholder';
import { elementRendered } from 'laraberg-vue/src/js/lib/element-ready';

const LarabergVue3 =
{
    install(app, options = {})
    {
        const Laraberg = window.Laraberg;

        app.config.globalProperties.$laraberg = () =>
        {
            return Laraberg;
        };

        app.config.globalProperties.$laraberg_options = () =>
        {
            return options;
        };

        (options.customCategories ?? []).forEach((category) =>
        {
            Laraberg.registerCategory(category.title, category.slug)
        });

        (options.customBlocks ?? []).forEach((block) =>
        {
            Laraberg.registerBlock(block.name, block.block)
        });

        (options.customFormats ?? []).forEach((format) =>
        {
            window.wp.richText.registerFormatType(format.name, format.format);
        });
    }
};

const InitLaraberg = (app) =>
{
    elementRendered('.components-form-file-upload button', element => element.remove());

    MediaUpload();
    // MediaPlaceholder();

    app.use(LarabergVue3, {
        prefix: process.env.VUE_APP_BACKEND_URL + 'laraberg',

        height: '100vh',

        customCategories: [
            {
                name: 'MAP',
                slug: 'map'
            },
        ],

        // laravelFilemanager: true,

        preserveMediaBlocks: true,

        customBlocks:[
            {
                name: 'blocks/section-block',
                block: SectionBlock,
            },
            {
                name: 'blocks/main-takeaway-block',
                block: MainTakeawayBlock,
            },
            {
                name: 'blocks/accordion-block',
                block: AccordionBlock,
            },
            {
                name: 'blocks/accordion-section-block',
                block: AccordionSectionBlock,
            },
            {
                name: 'blocks/quiz-block',
                block: QuizBlock,
            },
            {
                name: 'blocks/check-quiz-block',
                block: CheckQuizBlock,
            },
            {
                name: 'blocks/video-block',
                block: VideoBlock,
            },
            {
                name: 'blocks/todo-block',
                block: ToDoBlock,
            },
            {
                name: 'blocks/pdf-block',
                block: PDFBlock,
            },
        ],

        customFormats: [
            {
                name: 'blocks/justify-text',
                format: JustifyText,
            },
        ],
    });
};

export default InitLaraberg;
