<template>
    <div class="map-page map-page-news">
        <img src="@/assets/images/mobile/news-header.jpg" class="map-page-header-img" />
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Discover the Latest News</h1>
            <basic-dropdown :options="filters" v-model="filter_by" placeholder="Explore our blogs by:"></basic-dropdown>
            <dropdown
                v-if="filter_by === 'Language'"
                placeholder="Select your language"
                v-model="topic"
                :closeOnSelect="false"
                :options="topics"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :closeOnSelect="false"
                :options="countries"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Tag'"
                placeholder="Select your tag"
                v-model="tag"
                :closeOnSelect="false"
                :options="tags"
                >
            </dropdown>
            <articles :style="'news'" :loading="loading">
                <news-post
                    v-for="(news, key) in news_posts"
                    :key="key"
                    :featured="key === 0"
                    :topic="topic"
                    :image="news.image_file"
                    :title="news.title"
                    :author="news.author"
                    :date="news.created_at"
                    :excerpt="news.summary"
                    :link="'/news/' + news.id"
                    >
                </news-post>
            </articles>
            <button class="map-button map-load-more">LOAD MORE</button>
            <div class="map-center-access">
                <p>You have access to news posts from:</p>
                <router-link
                    :to="{ name: center }"
                    v-for="(center, key) in user.centers"
                    :key="key"
                    >
                    {{ center }}
                </router-link>
            </div>
        </div>
        <map-sidebar class="map-sidebar-news">
            <basic-dropdown :options="filters" v-model="filter_by" placeholder="Explore our blogs by:"></basic-dropdown>
            <dropdown
                v-if="filter_by === 'Language'"
                placeholder="Select your language"
                v-model="topic"
                :closeOnSelect="false"
                :options="topics"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :closeOnSelect="false"
                :options="countries"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Tag'"
                placeholder="Select your tag"
                v-model="tag"
                :closeOnSelect="false"
                :options="tags"
                >
            </dropdown>
        </map-sidebar>
    </div>
</template>

<script>
    import BasicDropdown from '@/components/Inputs/BasicDropdown';
    import Articles from '@/components/Articles/Articles';
    import NewsPost from '@/components/Articles/NewsPost';

    import MapSidebar from '@/components/MapSidebar';

    export default
    {
        name: 'Dashboard',
        components:
        {
            BasicDropdown,
            Articles,
            NewsPost,
            MapSidebar,
        },
        data()
        {
            return {
                loading: false,
                news_posts:
                [
                    {
                        id: 1,
                        image_file: require('@/assets/images/welcome-01.jpg'),
                        title: 'Lorem ipsum dolor sit amet',
                        author:
                        {
                            name: 'Jane Doe',
                        },
                        published_at: '02/03/2021',
                        summary: 'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
                    },
                    {
                        id: 2,
                        image_file: null,
                        title: 'Lorem ipsum dolor sit amet',
                        author:
                        {
                            name: 'Jane Doe',
                        },
                        published_at: '02/03/2021',
                        summary: 'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
                    },
                    {
                        id: 1,
                        image_file: require('@/assets/images/welcome-01.jpg'),
                        title: 'Lorem ipsum dolor sit amet',
                        author:
                        {
                            name: 'Jane Doe',
                        },
                        published_at: '02/03/2021',
                        summary: 'Lorem ipsum dolor sit amet, consectetuer adipiscing.',
                    },
                ],
                filters: [
                    'Country',
                    'Language',
                    'Tag',
                ],
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
    };
</script>
