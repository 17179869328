<template>
    <div class="map-page map-page-post map-page-course-post">
        <div class="map-page-content map-center-layout">
            <p class="map-course-progress-caption">Finished: <span>{{ course_progress }}% of the course</span></p>
            <progress max="100" :value="course_progress"> {{ course_progress }}% </progress>
            <div class="map-course-info">
                <img :src="get_image(post)" />
                <div class="map-course-info-title">
                    <h1>{{ post.title }}</h1>
                    <p>by {{ get_author_name(post) }}</p>
                </div>
                <p class="map-course-summary">{{ post.summary }}</p>
            </div>
        </div>
        <map-sidebar class="map-sidebar-course">
            <lessons label="Lessons of the Course">
                <lesson
                    v-for="(lesson, key) in post.lessons"
                    :key="key"
                    :title="lesson.title"
                    :excerpt="lesson.excerpt"
                    :progress="lesson.is_done ? 'done' : 'start'"
                    :link="get_lesson_link(lesson)"
                    @click.prevent="on_lesson_click(key)"
                    >
                </lesson>
            </lessons>
        </map-sidebar>
    </div>
</template>

<script>
    import Lessons from '@/components/Lessons/Lessons';
    import Lesson from '@/components/Lessons/Lesson';
    import MapSidebar from '@/components/MapSidebar';

    import { PostService, PostType } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            Lessons,
            Lesson,
            MapSidebar,
        },
        props:
        {
            data: Object,
        },
        setup()
        {
            return {
                PostType: PostType,
            };
        },
        data()
        {
            return {
                is_shared: false,
                loading: false,
                course_progress: 0,
                post: {},
            };
        },
        async mounted()
        {
            if (this.data)
            {
                this.is_shared = true;
                this.post = this.data;

                console.log(this.post);
            }
            else
            {
                this.is_shared = false;
                await this.get_post(this.$route.params.id);
            }
        },
        methods:
        {
            async get_post(id)
            {
                this.loading = true;

                this.post = (await PostService.get(PostType.E_LEARNING, id)).data;

                this.post.lessons = this.post.lessons.filter((l) => l.status === 'ACTIVE');

                const lessons_count = this.post.lessons.length;
                const done_lessons = this.post.lessons.filter((l) => !!l.is_done).length;

                this.course_progress = Math.round(100 / lessons_count * done_lessons);

                this.loading = false;
            },

            get_image(post)
            {
                if (post.cover_image && post.cover_image.id)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + post.cover_image.id;
                }
                else if (post.cover_image)
                {
                    return post.cover_image;
                }
            },

            on_lesson_click(index)
            {
                this.$router.push({ name: 'SharePublic', params: { token: this.$route.params.token, lesson: index } });
            },

            get_lesson_link(lesson)
            {
                if (this.is_shared)
                {
                    return '';
                }

                return '/e-learning/lesson/' + lesson.id;
            },

            get_author_name(post)
            {
                if (post.author && post.author.name)
                {
                    return `${post.author.name} ${post.author.surname}`;
                }
            },
        },
    };
</script>
