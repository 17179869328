<template>
    <div class="map-page-welcome">
        <img src="@/assets/images/welcome-01.jpg" />
        <div class="map-page-content">
            <router-link to="/" class="map-logo"></router-link>
            <h1>Welcome</h1>
            <p>The <b>Movement Accelerator Platform</b> is focused on developing values-based communications, countering the opposition and supporting social movements. The model combines the expertise of the IPPF Secretariat and Member Associations to drive a global approach to learning that supports contextualised local responses.</p>

            <router-link to="/user/login" class="map-button">LOGIN</router-link>

            <p>For access to the platform <router-link to="/user/signup" class="icph-label-button">Register here.</router-link></p>
        </div>
    </div>
</template>

<script>
export default
{
    name: 'Welcome',
};
</script>
