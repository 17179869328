export default
{
    mounted: function(el, binding)
    {
        el.click_outside_event = function (ev)
        {
            if (!(el == ev.target || el.contains(ev.target)))
            {
                if (binding.value instanceof Function)
                {
                    binding.value(ev);
                }
            }
        };

        document.body.addEventListener('click', el.click_outside_event);
    },
    unmounted: function(el)
    {
        document.body.removeEventListener('click', el.click_outside_event);
    }
};
