import Sortable from 'sortablejs';

export const QuizTypes = {
    x2: '2',    // Quiz with 2 columns.
    x3: '3',    // Quiz with 3 columns.
};

export const Quiz = {
    /**
     *  Initialize all quizes on the page.
     */
    init_all()
    {
        document.querySelectorAll('.quiz-block').forEach((el) =>
        {
            this.init(el);
        });
    },

    /**
     *  Initialize a quiz.
     *
     *  @param DOMElement el
     */
    init(el)
    {
        const type = el.dataset.type || QuizTypes.x2;
        const selector = type === QuizTypes.x2 ? '.quiz-left' : '.quiz-middle, .quiz-right';

        this.prepare(el, type);
        this.scramble(el, type);

        el.querySelectorAll(selector).forEach((el) =>
        {
            Sortable.create(el, {
                ghostClass: 'item-ghost',
                scroll: true,
                scrollSensitivity: 100,
                scrollSpeed: 10,
                onStart: (ev) =>
                {
                    this.reset(ev.target);
                    ev.target.querySelector('.item-ghost').innerHTML = 'Move the card up and down to order and match';
                    ev.target.closest('.quiz-canvas').classList.add('quiz-dragging');
                    ev.target.classList.add('current');
                },
                onEnd: (ev) =>
                {
                    this.check(ev.target);
                    ev.target.closest('.quiz-canvas').classList.remove('quiz-dragging');
                    ev.target.classList.remove('current');
                    ev.item.innerHTML = ev.clone.innerHTML;
                },
            });
        });
    },

    /**
     *  Prepare quiz HTML.
     *
     *  @param DOMElement el
     *  @param String type
     */
    prepare(el, type)
    {
        const col_classes = type === QuizTypes.x2 ? ['quiz-left', 'quiz-middle'] : ['quiz-left', 'quiz-middle', 'quiz-right'];
        const child_selectors = type === QuizTypes.x2 ? ['.item-type-a', '.item-type-b'] : ['.item-type-d', '.item-type-e', '.item-type-c'];
        const canvas = el.querySelector('.quiz-canvas');

        col_classes.forEach((class_name, index) =>
        {
            const el = document.createElement('div');

            el.className = class_name;

            canvas.querySelectorAll(child_selectors[index]).forEach((child, index) =>
            {
                child.dataset.originalIndex = index;

                child.insertAdjacentHTML('beforeend', '<span class="quiz-state"></span>');

                el.appendChild(child);
            });

            canvas.appendChild(el);
        });
    },

    /**
     *  Scramble quiz items.
     *
     *  @param DOMElement el
     *  @param String type
     */
    scramble(el, type)
    {
        const selector = type === QuizTypes.x2 ? '.quiz-left' : '.quiz-middle, .quiz-right';

        el.querySelectorAll(selector).forEach((el) =>
        {
            for (let i = el.children.length; i >= 0; i--)
            {
                el.appendChild(el.children[Math.random() * i | 0]);
            }
        });
    },

    /**
     *  Check all children for correct or incorrect placement.
     *
     *  @param DOMElement el
     */
    check(el)
    {
        el.children.forEach((el, index) =>
        {
            el.classList.toggle('item-correct', el.dataset.originalIndex == index);
            el.classList.toggle('item-incorrect', el.dataset.originalIndex != index);
        });
    },

    /**
     *  Reset all children for correct or incorrect placement.
     *
     *  @param DOMElement el
     */
    reset(el)
    {
        el.children.forEach((el) =>
        {
            el.classList.remove('item-correct');
            el.classList.remove('item-incorrect');
        });
    },
};

export default { Quiz, QuizTypes };
