import { ApiService } from './api-service';

const CenterService =
{
    all(params)
    {
        return ApiService.get(`/api/centers`, params);
    },

    get(id)
    {
        return ApiService.get(`/api/centers/${id}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/centers/${id}`, data);
    },
};

export { CenterService };
