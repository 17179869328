const el = window.wp.element.createElement;
const InnerBlocks = window.wp.blockEditor.InnerBlocks;

export const MainTakeawayBlock = {
    title: 'Main Takeaway Block',
    icon: 'admin-page',
    category: 'map',

    edit: function ()
    {
        return el(
            'div',
            { className: 'map-block-main-takeaway' },
            el(InnerBlocks),
        );
    },

    save: function ()
    {
        return el(
            'div',
            { className: 'takeaway-block' },
            el(InnerBlocks.Content),
        );
    },
};

export default MainTakeawayBlock;
