const wp = window.wp;
const el = wp.element.createElement;

const InspectorControls = wp.blockEditor.InspectorControls;
const PanelBody = wp.components.PanelBody;

const Button = wp.components.Button;
const SelectControl = wp.components.SelectControl;
const RichText = wp.blockEditor.RichText;

export const QuizBlock = {
    title: 'Quiz Block',
    icon: 'forms',
    category: 'map',
    attributes:
    {
        heading: { type: 'string' },
        cite: { type: 'string' },
        type:
        {
            type: 'string',
            default: '2',
        },
        items:
        {
            type: 'array',
            default: [
                {
                    word: '',
                    item_b: '',
                },
            ],
        }
    },
    edit: function (props)
    {
        const handle_attr_change = (prop, index, value) =>
        {
            const items = [ ...props.attributes.items ];

            items[index][prop] = value;

            props.setAttributes({ items });
        };

        let items = props.attributes.items.map(( item, index ) =>
        {
            if (props.attributes.type === '2')
            {
                return [
                    el('div', { className: 'quiz-item item-type-a' },
                        el(RichText, {
                            tagName: 'span',
                            value: item.word,
                            allowedFormats: ['core/bold', 'core/italic'],
                            placeholder: 'Word',
                            onChange: (content) => handle_attr_change('word', index, content),
                        }),
                    ),
                    el('div', { className: 'quiz-item item-type-b' },
                        el(RichText, {
                            tagName: 'span',
                            value: item.item_b,
                            allowedFormats: ['core/bold', 'core/italic'],
                            onChange: (content) => handle_attr_change('item_b', index, content),
                        }),
                    ),
                ];
            }
            else
            {
                return [
                    el('div', { className: 'quiz-item item-type-d' },
                        el(RichText, {
                            tagName: 'span',
                            value: item.word,
                            allowedFormats: ['core/bold', 'core/italic'],
                            placeholder: 'Word',
                            onChange: (content) => handle_attr_change('word', index, content),
                        }),
                    ),
                    el('div', { className: 'quiz-item item-type-e' },
                        el(RichText, {
                            tagName: 'span',
                            value: item.item_b,
                            allowedFormats: ['core/bold', 'core/italic'],
                            onChange: (content) => handle_attr_change('item_b', index, content),
                        }),
                    ),
                    el('div', { className: 'quiz-item item-type-c' },
                        el(RichText, {
                            tagName: 'span',
                            value: item.item_c,
                            allowedFormats: ['core/bold', 'core/italic'],
                            onChange: (content) => handle_attr_change('item_c', index, content),
                        }),
                    ),
                ];
            }
        });

        return [
            el(InspectorControls,
                { key: 1 },
                el(PanelBody,
                    { title: 'Attributes' },
                    el(SelectControl, {
                        label: 'Type',
                        options: [
                            { label: '2x Quiz', value: '2' },
                            { label: '3x Quiz', value: '3' },
                        ],
                        onChange: (type) =>
                        {
                            props.setAttributes({ type: type });
                        },
                    }),
                )
            ),
            el('div',
                {
                    key: 2,
                    className: 'map-quiz-block',
                    'data-type': props.attributes.type,
                },
                el('p', {},
                    el('b', {}, 'Quick quiz:'),
                    el('br'),
                    el(RichText, {
                        tagName: 'span',
                        value: props.attributes.heading,
                        allowedFormats: ['core/bold', 'core/italic'],
                        placeholder: 'Heading...',
                        onChange: (content) =>
                        {
                            props.setAttributes({ heading: content });
                        },
                    }),
                ),
                el('div',
                    { className: 'quiz-canvas' },
                    items,
                    el('div',
                        { className: 'quiz-panel' },
                        el(Button, {
                            variant: 'primary',
                            isDefault: true,
                            onClick: () =>
                            {
                                const items = [ ...props.attributes.items ];

                                items.push({ item_a: '', item_b: '' });

                                props.setAttributes({ items: items });
                            },
                        }, 'Add item'),
                    ),
                ),
                el(RichText, {
                    tagName: 'cite',
                    value: props.attributes.cite,
                    allowedFormats: ['core/bold', 'core/italic'],
                    placeholder: 'Cite...',
                    onChange: (content) =>
                    {
                        props.setAttributes({ cite: content });
                    },
                }),
            )
        ];
    },

    save: function (props)
    {
        let items = props.attributes.items.map(( item ) =>
        {
            if (props.attributes.type === '2')
            {
                return [
                    el(RichText.Content, {
                        tagName: 'div',
                        className: 'quiz-item item-type-a',
                        value: item.word,
                    }),
                    el(RichText.Content, {
                        tagName: 'div',
                        className: 'quiz-item item-type-b',
                        value: item.item_b,
                    }),
                ];
            }
            else
            {
                return [
                    el(RichText.Content, {
                        tagName: 'div',
                        className: 'quiz-item item-type-d',
                        value: item.word,
                    }),
                    el(RichText.Content, {
                        tagName: 'div',
                        className: 'quiz-item item-type-e',
                        value: item.item_b,
                    }),
                    el(RichText.Content, {
                        tagName: 'div',
                        className: 'quiz-item item-type-c',
                        value: item.item_c,
                    }),
                ];
            }
        });

        return el('div',
            {
                className: 'quiz-block',
                'data-type': props.attributes.type,
            },
            el('p', {},
                el('b', {}, 'Quick quiz:'),
                el('br'),
                el(RichText.Content, {
                    tagName: 'span',
                    value: props.attributes.heading,
                }),
            ),
            el('div',
                { className: 'quiz-canvas' },
                items,
            ),
            el(RichText.Content, {
                tagName: 'cite',
                value: props.attributes.cite,
            }),
        );
    },
};

export default QuizBlock;
