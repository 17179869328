import { Utils } from '@/helpers';

const el = window.wp.element.createElement;
const InnerBlocks = window.wp.blockEditor.InnerBlocks;

export const AccordionSectionBlock = {
    title: 'Accordion Section Block',
    icon: 'align-wide',
    category: 'map',
    attributes:
    {
        title: { type: 'string' },
    },
    edit: function (props)
    {
        return el(
            'div',
            { className: 'map-block-accordion-section' },
            el('h1', {},
                el('input', {
                    type: 'text',
                    value: props.attributes.title,
                    onChange: function (ev)
                    {
                        props.setAttributes({ title: ev.target.value });
                    },
                }),
            ),
            el(InnerBlocks),
        );
    },

    save: function (props)
    {
        const title_id = Utils.slugify(props.attributes.title);

        return el(
            'div',
            { className: 'card' },
            el(
                'div',
                { className: 'card-header', id: title_id, },
                el(
                    'h5',
                    { className: 'mb-0' },
                    el(
                        'button',
                        {
                            className: 'btn btn-link collapsed',
                            'data-toggle': 'collapse',
                            'data-target': '#collapse-' + title_id,
                            'aria-expanded': 'true',
                            'aria-controls': 'collapse-' + title_id,
                        },
                        props.attributes.title,
                    ),
                ),
            ),

            el(
                'div',
                { id: 'collapse-' + title_id, className: 'collapse', 'aria-labelledby': title_id },
                el(
                    'div',
                    { className: 'card-body' },
                    el(InnerBlocks.Content),
                ),
            ),
        );
    },
};

export default AccordionSectionBlock;
