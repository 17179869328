import { ApiService } from './api-service';
import { TokenService } from './token-service';

class AuthenticationError extends Error
{
    constructor(error_code, message)
    {
        super(message)

        this.name = this.constructor.name;
        this.message = message;
        this.error_code = error_code;
    }
}

const AuthService =
{
    /**
     *  Login the user.
     *
     *  @returns access_token
     *  @throws AuthenticationError
     */
    login: async function (email, password)
    {
        try
        {
            await ApiService.get('/sanctum/csrf-cookie');
            const response = await ApiService.request({
                method: 'post',
                url: '/api/auth/login',
                data:
                {
                    email: email,
                    password: password,
                },
            });

            TokenService.save_token(response.data.token);
            ApiService.set_header();

            return response.data.token;
        }
        catch (ex)
        {
            throw new AuthenticationError(ex.status, ex.data.message);
        }
    },

    /**
     *  Validate user's two factor auth code.
     *
     *  @returns two_factor_token
     *  @throws AuthenticationError
     */
    login_two_factor: async function (code)
    {
        try
        {
            // await ApiService.get('/sanctum/csrf-cookie');
            const response = await ApiService.request({
                method: 'post',
                url: '/api/auth/two-factor',
                data:
                {
                    code: code,
                },
            });

            if (response.data && response.data.success)
            {
                TokenService.save_two_factor_token(response.data.token)
            }
            else
            {
                throw new Error(response.data.error);
            }

            return response.data.token;
        }
        catch (ex)
        {
            throw new AuthenticationError(ex.status, ex.data.message);
        }
    },

    async logout()
    {
        const response = await ApiService.post('/api/auth/logout');
        
        TokenService.remove_token();
        TokenService.remove_two_factor_token();
        ApiService.clear_header();

        return response;
    },

    activate: function (data)
    {
        return ApiService.post('/api/auth/activate', data);
    },

    request_activate: function (data)
    {
        return ApiService.post('/api/auth/request-activate', data);
    },

    register: function (data)
    {
        return ApiService.post('/api/auth/register', data);
    },

    request_reset_password: async function (data)
    {
        await ApiService.get('/sanctum/csrf-cookie');
        return ApiService.post('/api/auth/password/forgot', data);
    },

    submit_reset_password: async function (data)
    {
        await ApiService.get('/sanctum/csrf-cookie');
        return ApiService.post('/api/auth/password/reset', data);
    },
};

export default AuthService;

export {AuthService, AuthenticationError};
