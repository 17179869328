import { createApp } from 'vue';

import QuestionsQuiz from '@/components/Lessons/Blocks/QuestionsQuiz';
import TodoList from '@/components/Lessons/Blocks/TodoList';
import PdfViewer from '@/components/PdfViewer';
import VideoPlayer from '@/components/VideoPlayer';

import { Quiz } from '@/plugins/quiz';

const GuttenbergContent =
{
    /**
     *  Init content.
     */
    init()
    {
        setTimeout(() =>
        {
            this.insert_blocks();

            Quiz.init_all();
        }, 50);
    },

    /**
     *  Insert Blocks
     */
    insert_blocks()
    {
        document.querySelectorAll('.check-quiz-block').forEach((el) =>
        {
            let questions = JSON.parse(el.innerText.trim());

            createApp(QuestionsQuiz, {
                questions: questions || [],
            }).mount(el);
        });

        document.querySelectorAll('.pdf-block').forEach((el) =>
        {
            let pdf = JSON.parse(el.innerText.trim());

            if (pdf && pdf.url)
            {
                createApp(PdfViewer, {
                    src: pdf.url || '',
                }).mount(el);
            }
        });

        document.querySelectorAll('.video-block').forEach((el) =>
        {
            let video = JSON.parse(el.innerText.trim());

            if (video && video.url)
            {
                createApp(VideoPlayer, {
                    src: video.url || '',
                    title: video.title,
                    date: video.created_at,
                    summary: video.summary,
                }).mount(el);
            }
        });

        document.querySelectorAll('.todo-block').forEach((el) =>
        {
            let items = JSON.parse(el.innerText.trim());

            if (items)
            {
                createApp(TodoList, {
                    items: items || [],
                }).mount(el);
            }
        });
    },
};

export { GuttenbergContent };
