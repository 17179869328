import { Utils } from '@/helpers';

const el = window.wp.element.createElement;
const InnerBlocks = window.wp.blockEditor.InnerBlocks;

export const SectionBlock = {
    title: 'Section Block',
    icon: 'admin-page',
    category: 'map',
    attributes:
    {
        title: { type: 'string' },
    },
    edit: function (props)
    {
        return el(
            'div',
            { className: 'map-block-section' },
            el('h1', {},
                el('input', {
                    type: 'text',
                    value: props.attributes.title,
                    onChange: function (ev)
                    {
                        props.setAttributes({ title: ev.target.value });
                    },
                }),
            ),
            el(InnerBlocks),
        );
    },

    save: function (props)
    {
        const title_id = Utils.slugify(props.attributes.title);

        return el(
            'section',
            { className: 'lesson-section' },
            el(
                'h1',
                { className: 'section-title', id: title_id, },
                props.attributes.title,
            ),
            el(InnerBlocks.Content),
        );
    },
};

export default SectionBlock;
