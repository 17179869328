<template>
    <footer class="main-footer">
        <p class="map-copyright">&copy; IPPF {{ year }}</p>
        <nav class="map-menu">
            <a class="map-menu-item">Terms of Use</a>
            <a class="map-menu-item">Privacy Policy</a>
        </nav>
    </footer>
</template>

<script>

    export default
    {
        name: 'Footer',
        components:
        {
        },
        data()
        {
            return {
                year: new Date().getFullYear(),
            };
        },
    }
</script>
