<template>
    <div class="map-map" :class="{ 'map-loading': loading }">
        <loader />
        <div
            class="map-map-viewport"
            ref="viewport"
            @mousedown="on_mousedown"
            @mousemove="on_mousemove"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="map.viewBox"
                :aria-label="map.label"
                class="map-svg-map"
                ref="map"
                >
                <slot name="before" />
                <path
                    v-for="(location) in map.locations"
                    :id="location.id"
                    :key="location.id"
                    :name="location.name"
                    :d="location.path"
                    class="svg-map__location"
                    :class="{ 'map-selected': is_selected(location), 'map-active': is_active(location) }"
                    :aria-label="location.name"
                    @click="select($event, location)"
                />
                <slot name="after" />
            </svg>
        </div>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import World from "@svg-maps/world";
    import { gsap } from 'gsap';

    const MIN_ZOOM_LEVEL = 2;
    const MAX_ZOOM_LEVEL = 16;

    export default
    {
        name: 'mv-map',
        components:
        {
            Loader,
        },
        props:
        {
            loading: Boolean,
            fullyExpanded: Boolean,
            modelValue: null,
            dataset:
            {
                type: Array,
                required: false,
            },
            countries: null,
            infograms: null,
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                map: World,
                zoom_level: 7,
                start_x: 0,
                start_y: 0,
                x_offset: 0,
                y_offset: 0,
                map_width: 1010,
                map_height: 666,
                current_map_width: 1010,
                current_map_height: 666,
                filters: {
                    country: '',
                },
                locations: null,
                mousedown: false,
                expanded: false,
                expand_popup: false,
                overlay_visible: true,
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            is_empty()
            {
                return !this.loading && !this.dataset.length;
            },
        },
        created()
        {
            const parts = this.map.viewBox.split(' ');

            this.map_width = parts[2];
            this.map_height = parts[3];

            document.addEventListener('mouseup', this.on_mouseup);

            // this.locations = this.map.locations.filter((item) => item.id.toUpperCase() in this.infograms).map((item) =>
            // {
            //     return {
            //         value: item.id.toUpperCase(),
            //         label: item.name,
            //     };
            // });

            // const viewport = this.$refs.viewport;
        },
        mounted()
        {
            this.update(false);
        },
        unmounted()
        {
            document.removeEventListener('mouseup', this.on_mouseup);
        },
        methods:
        {
            move_to(x, y, animate)
            {
                // this.x_offset = Math.min(1000, Math.max(x, 0));
                // this.y_offset = Math.min(1000, Math.max(y, 0));

                this.x_offset = Math.max(-100, Math.min(this.current_map_width - (this.$refs.viewport.offsetWidth / 2), x));
                this.y_offset = Math.max(-100, Math.min(this.current_map_height - (this.$refs.viewport.offsetHeight / 2), y));

                // this.x_offset = x;
                // this.y_offset = y;

                // console.log('x: ', x, 'y:', y);
                // console.log('x_offset: ', this.x_offset, 'y_offset:', this.y_offset);

                this.update(animate);
            },

            move_up()
            {
                this.move_to(this.x_offset, this.y_offset - 100, true);
            },

            move_down()
            {
                this.move_to(this.x_offset, this.y_offset + 100, true);
            },

            move_left()
            {
                this.move_to(this.x_offset - 100, this.y_offset, true);
            },

            move_right()
            {
                this.move_to(this.x_offset + 100, this.y_offset, true);
            },

            zoom_to(level)
            {
                level = Math.min(MAX_ZOOM_LEVEL, Math.max(MIN_ZOOM_LEVEL, level));

                this.zoom_level = level;

                this.update();
            },

            zoom_in()
            {
                this.zoom_to(this.zoom_level + 1);
            },

            zoom_out()
            {
                this.zoom_to(this.zoom_level - 1);
            },

            view_box()
            {
                const zoom_level = (MAX_ZOOM_LEVEL / 2) / this.zoom_level;
                // const zoom_level = 1;
                const x = this.x_offset;
                const y = this.y_offset;
                const map_width = this.map_width * zoom_level;
                const map_height = this.map_height * zoom_level;

                this.current_map_width = map_width;
                this.current_map_height = map_height;

                return `${x} ${y} ${map_width} ${map_height}`;
            },

            update(animate)
            {
                if (animate)
                {
                    gsap.to(this.$refs.map, {
                        duration: 1,
                        attr: { viewBox: this.view_box() },
                        ease: 'power3.inOut',
                    });
                }
                else
                {
                    this.$refs.map.setAttribute('viewBox', this.view_box());
                }
            },

            select(ev, item)
            {
                const code = item.id.toUpperCase();
                // const rect = ev.target.getBoundingClientRect();

                this.value = this.countries.filter((country) =>
                {
                    return country.code === code;
                })[0];
                
            },

            select_country()
            {
                const country = (this.filters.country ? this.filters.country : null);

                if (country)
                {
                    this.select(null, {id: country});
                }
            },

            is_selected(item)
            {
                return this.value && this.value.code === item.id.toUpperCase();
            },

            is_active(item)
            {
                const code = item.id.toUpperCase();

                return this.countries && this.countries.filter((l) => l.code === code).length;
            },

            on_mousedown(ev)
            {
                this.mousedown = true;
                this.start_x = ev.offsetX + this.x_offset;
                this.start_y = ev.offsetY + this.y_offset;
            },

            on_mousemove(ev)
            {
                if (this.mousedown)
                {
                    const x = this.start_x - ev.offsetX;
                    const y = this.start_y - ev.offsetY;

                    this.move_to(x, y);
                }
            },

            on_mouseup()
            {
                this.mousedown = false;
            },
        },
    }
</script>
