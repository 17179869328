<template>
    <div class="map-page map-page-post map-page-blog-post">
        <div class="map-page-content map-center-layout" :data-section-title="post.start_at">
            <h1 class="map-post-title">{{ post.title }}</h1>
            <p class="map-post-summary">{{ post.summary }}</p>
            <ul class="map-post-tags">
                <li v-for="(tag) in post.tags" :key="tag">#{{ tag }}</li>
            </ul>
            <div class="map-post-content page-dynamic-content" v-html="post.content"></div>
            <!-- <articles label="Other Events" :style="'mini-events'">
                <event-post
                    v-for="(event, key) in posts"
                    :key="key"
                    :topic="topic"
                    :image="event.cover_image"
                    :title="event.title"
                    :author="event.author"
                    :start-date="event.start_at"
                    :end-date="event.end_at"
                    :location="event.location"
                    :excerpt="event.summary"
                    :link="'/event/' + event.id"
                    >
                </event-post>
            </articles> -->
        </div>
        <map-sidebar class="map-sidebar-event">
            <articles label="Related Events" :slider="true" :posts="posts" type="event">
            </articles>
        </map-sidebar>
    </div>
</template>

<script>
    import Articles from '@/components/Articles/Articles';
    // import EventPost from '@/components/Articles/EventPost';
    import MapSidebar from '@/components/MapSidebar';

    import { GuttenbergContent } from '@/plugins/guttenberg/content';

    import { PostService, PostType } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            Articles,
            // EventPost,
            MapSidebar,
        },
        props:
        {
            data: Object,
        },
        data()
        {
            return {
                loading: false,
                post: {},
                posts: [],
            };
        },
        async beforeRouteUpdate(to)
        {
            this.loading = true;

            await this.get_post(to.params.id);
            await this.get_posts();

            GuttenbergContent.init();

            this.loading = false;
        },
        async mounted()
        {
            // const ps_el = document.querySelector('#app > .ps');
            //
            // ps_el.scrollTo(0, 0, 'smooth');
            this.loading = true;

            if (this.data)
            {
                this.post = this.data;
            }
            else
            {
                await this.get_post(this.$route.params.id);
                await this.get_posts();
            }

            GuttenbergContent.init();

            this.loading = false;
        },
        methods:
        {
            async get_post(id)
            {
                this.post = (await PostService.get(PostType.EVENT, id)).data;
            },

            async get_posts()
            {
                const response = (await PostService.all(PostType.EVENT, {
                    page: this.page,
                    related: this.post.id,
                    'per-page': 3,
                    'order-by': 'start_at',
                })).data;
                const posts = response.data;

                this.posts = posts;
            },

            get_author_picture(post)
            {
                if (post.author && post.author.image)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + post.author.image.id;
                }
            },

            get_author_name(post)
            {
                if (post.author && post.author.name)
                {
                    return `${post.author.name} ${post.author.surname}`;
                }
            },
        },
    };
</script>
