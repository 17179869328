<template>
    <div class="map-page map-page-contact">
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Contact Us</h1>
            <p class="map-page-subtitle">Fill in the form and we will get back to you shortly!</p>
            <form @submit.prevent="send" :class="{ 'map-loading': sending }">
                <loader />
                <div class="map-contact-panel">
                    <p v-if="success" class="map-contact-success">Message successfully sent</p>
                    <base-input
                        placeholder="Name"
                        v-model="model.name"
                        >
                    </base-input>
                    <base-input
                        placeholder="Surname"
                        v-model="model.surname"
                        >
                    </base-input>
                    <base-input
                        placeholder="Email"
                        v-model="model.email"
                        >
                    </base-input>
                    <base-input
                        placeholder="Organization"
                        v-model="model.organization"
                        >
                    </base-input>
                    <dropdown
                        placeholder="Select country"
                        v-model="model.country"
                        :options="countries"
                        >
                    </dropdown>
                    <base-textarea
                        placeholder="Message (1000 characters max)"
                        v-model="model.message"
                        >
                    </base-textarea>
                </div>
                <Button type="submit" class="map-send-button" color="primary">
                    SEND
                </Button>
            </form>
        </div>
        <map-sidebar class="map-sidebar-contact">
        </map-sidebar>
    </div>
</template>

<script>
    import MapSidebar from '@/components/MapSidebar';
    import BaseInput from '@/components/Inputs/BaseInput';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';
    import Dropdown from '@/components/Inputs/Dropdown';
    import Button from '@/components/Buttons/Button';
    import Loader from  '@/components/Loader';

    export default
    {
        name: 'Contact',
        components:
        {
            MapSidebar,
            Loader,
            Button,
            BaseInput,
            BaseTextarea,
            Dropdown,
        },
        data()
        {
            return {
                sending: false,
                success: false,
                model:
                {
                },
            };
        },
    };
</script>
