<template>
    <div class="map-page map-page-about">
        <div class="map-page-content map-center-layout">
            <h1 class="map-lineunder">About the Global Movement Accelerator Platform</h1>
            <p>In recent years, we have witnessed a concerted attack on abortion, contraception, gender equality, LGBTIQ+ rights and comprehensive sexuality education, as populist governments and socially conservative groups attempt to roll back hard-won gains in sexual and reproductive rights. This so-called ‘anti-gender’ movement, which is well-organized and well-funded, is gaining ground at national, regional and global levels.</p>
            <h3>Together to drive progressive change.</h3>
            <p>Faced with this backlash, our movement must step up its game. To defend and advance social justice, equality and rights for all, we need to widen public support and build coalitions. IPPF has therefore launched a global Movement Accelerator Platform to drive progressive change through research, innovation and learning in support of local responses.</p>
            <p>The platform includes resources and case studies on various topics such as tips to reframe the debates around shared values. This entails understanding the ‘movable middle’ in each country (people who are receptive to being swayed), monitoring narrative trends and creating powerful messages that resonate with people’s values.</p>
            <h3>Our aim</h3>
            <p>To ensure that MAs and partners have increased and more agile access to information, resources and strategies to neutralize attacks</p>
            <p>To amplify and coordinate movements and support grassroots activists, equipping them with research, resources and skills.</p>
            <p>Fostering solidarity and learning is at the heart of our approach. We know that it is essential to learn from each other, coordinate responses and show solidarity across borders and sectors. That’s why IPPF is nurturing global communities to disseminate knowledge, lessons learnt and best practice. We also share intelligence and offer guidance.</p>
        </div>
        <map-sidebar></map-sidebar>
    </div>
</template>

<script>
    import MapSidebar from '@/components/MapSidebar';

    export default
    {
        name: 'About',
        components:
        {
            MapSidebar,
        },
        data()
        {
            return {
            };
        },
        async mounted()
        {
        },
        methods:
        {
        }
    };
</script>
