<template>
    <div
        class="map-articles"
        :data-label="label"
        :class="['map-style-' + style, {
            'map-loading': loading,
            'map-article-single': single,
        }]"
    >
        <loader />
        <slot v-if="!posts"></slot>
        <template v-else>
            <p v-if="!visible_posts.length">There are no {{ type }}s to show</p>
            <post
                v-else
                v-for="(post, key) in visible_posts"
                :key="key"
                :featured="key === 0"
                :image="post.cover_image"
                :title="post.title"
                :date="post.published_at || (type === 'event' ? post.start_at + ' - ' + post.end_at : post.created_at)"
                :excerpt="post.summary"
                :link="'/' + type + '/' + post.id"
                >
            </post>
        </template>
        <nav v-if="slider && visible_posts.length" class="map-articles-nav">
            <button class="map-articles-prev" @click="prev"></button>
            <button class="map-articles-next" @click="next"></button>
        </nav>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import Post from '@/components/Articles/Post';

    export default
    {
        name: 'articles',
        components:
        {
            Loader,
            Post,
        },
        props:
        {
            loading: Boolean,
            slider: Boolean,
            single: Boolean,
            label: String,
            type: String,
            style: String,
            posts: Object,
        },
        data()
        {
            return {
                current_index: 0,
                visible_posts: [],
            };
        },
        watch:
        {
            posts()
            {
                if (this.posts && this.posts.length)
                {
                    this.visible_posts = [this.posts[this.current_index]];
                }
            },
        },
        mounted()
        {
        },
        methods:
        {
            prev()
            {
                this.current_index = Math.max(0, this.current_index - 1);

                this.visible_posts = [this.posts[this.current_index]];
            },

            next()
            {
                this.current_index = Math.min(this.posts.length - 1, this.current_index + 1);

                this.visible_posts = [this.posts[this.current_index]];
            },
        }

    }
</script>
