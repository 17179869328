const wp = window.wp;
const el = wp.element.createElement;
const { Component } = wp.element;
const { compose, ifCondition } = wp.compose;
const { withSelect, withDispatch } = wp.data;
const { RichTextToolbarButton } = wp.blockEditor;

const JUSTIFY_NAME = 'blocks/justify-text';
const JUSTIFY_TITLE = 'Align text justify';

class JustifyComponent extends Component
{
    render()
    {
        const {
			blockId,
			isBlockJustified,
			updateBlockAttributes,
		} = this.props;

		const onToggle = () => {
			updateBlockAttributes( blockId, { align: isBlockJustified ? null : 'justify' } );
		};

		return el(RichTextToolbarButton, {
			icon: 'editor-justify',
			title: JUSTIFY_TITLE,
			onClick: onToggle,
			isActive: isBlockJustified,
		});
    }
}

const Justify = compose(
	withSelect( ( select ) => {
		// const isDisabled = select( 'core/edit-post' ).isFeatureActive( 'disableEditorsKitJustifyFormats' );
		const selectedBlock = select( 'core/block-editor' ).getSelectedBlock();
		// if ( isDisabled || ! selectedBlock ) {
		// 	return {
		// 		isDisabled,
		// 	};
		// }
		return {
			// isDisabled,
			blockId: selectedBlock.clientId,
			blockName: selectedBlock.name,
			// isBlockJustified: 'justify' === get( selectedBlock, 'attributes.align' ),
			isBlockJustified: 'justify' === (selectedBlock || 'attributes.align'),
			formatTypes: select( 'core/rich-text' ).getFormatTypes(),
		};
	} ),
	withDispatch( ( dispatch ) => ( {
		updateBlockAttributes: dispatch( 'core/block-editor' ).updateBlockAttributes,
	} ) ),
	ifCondition( ( props ) => {
		if ( props.isDisabled || ! props.blockId ) {
			return false;
		}
		// const checkFormats = props.formatTypes.filter( ( formats ) => formats.name === 'wpcom/justify' );
		return 'core/paragraph' === props.blockName/* && checkFormats.length === 0;*/
	} )
)( JustifyComponent );

const { Fragment } = wp.element;

const JustifyText = {
	name: JUSTIFY_NAME,
	title: JUSTIFY_TITLE,
	tagName: 'p',
	className: null,
	attributes: {
		style: 'style',
	},
	edit( { isActive, value, onChange, activeAttributes } ) {
		return el(Fragment, {},
				el(Justify,
					{
						name: name,
						isActive: isActive,
						value: value,
						onChange: onChange,
						activeAttributes: activeAttributes,
					})
		);
	},
};

export  { JustifyText };
