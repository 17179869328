<template>
    <div class="map-page map-page-user-profile">
        <div class="map-page-content map-center-layout">
            <div class="map-profile-header">
                <profile-picture
                    :src="get_user_picture()"
                    :data-notifications="user.notifications && (user.notifications.length + 1)"
                    >
                </profile-picture>
                <div class="map-profile-info">
                    <span class="map-user-name">{{ user.name + ' ' + user.surname }}</span>
                    <span class="map-user-country">{{ user.country }}</span>
                    <span class="map-user-sep">-</span>
                    <span class="map-user-organization">{{ user.organization }}</span>
                    <div class="map-center-access">
                        <router-link
                            :to="{ name: center }"
                            v-for="(center, key) in user.centers"
                            :key="key"
                            >
                            {{ center }}
                        </router-link>
                    </div>
                </div>
                <div class="map-profile-actions">
                    <router-link class="map-button map-color-primary" :to="{ name: 'UserProfileEdit' }">EDIT PROFILE</router-link>
                    <router-link class="map-button map-color-gray" :to="{ name: 'Logout' }">LOGOUT</router-link>
                </div>
            </div>
            <p>{{ user.bio }}</p>
            <user-notifications v-if="user.receive_notifications" :user="user" />
        </div>
        <map-sidebar class="map-sidebar-user-profile">
        </map-sidebar>
    </div>
</template>

<script>
    import ProfilePicture from '@/components/ProfilePicture';
    import UserNotifications from '@/components/UserNotifications';
    import MapSidebar from '@/components/MapSidebar';

    export default
    {
        name: 'UserProfile',
        components:
        {
            ProfilePicture,
            UserNotifications,
            MapSidebar,
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        data()
        {
            return {
            };
        },
        methods:
        {
            get_user_picture()
            {
                return (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0);
            },
        },
    };
</script>
