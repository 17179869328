<template>
    <div>
        <component
            v-if="!loading && shared_component !== ''"
            :is="shared_component"
            :data="shared_link.data"
            >
        </component>
        <div class="map-share-join-form">
            <template v-if="!user">
                <div class="map-share-login" :class="{ 'map-loading': loading_login }">
                    <h1 class="map-lineunder">Do you already have an account</h1>
                    <loader />
                    <form @submit.prevent="login">
                        <div>
                            <base-input
                                type="email"
                                placeholder="Email"
                                v-model="login_model.email"
                                >
                            </base-input>
                            <base-input
                                type="password"
                                placeholder="Password"
                                :error="error"
                                v-model="login_model.password"
                                >
                            </base-input>
                            <router-link to="/user/recover" class="icph-label-button">Recover password / account</router-link>
                            <span>&nbsp;|&nbsp;</span>
                            <a href="mailto:support_movementaccelerator@ippf.org" target="_blank">Contact Us</a>
                        </div>
                        <Button type="submit" class="map-color-primary">LOGIN</Button>
                    </form>
                </div>
                <h1 class="map-lineunder">Join the Movement Accelerator</h1>
                <p>By filling in this form, you consent to the MAP team sending you regular email updates about new content on the MAP online platform. You can unsubscribe from those emails at any point by emailing Support_MovementAccelerator@ippf.org.</p>
                <loader />
                <form @submit.prevent="signup">
                    <base-input
                        type="email"
                        placeholder="Email"
                        v-model="model.email"
                        :error="get_errors('email')"
                        >
                    </base-input>
                    <base-input
                        type="text"
                        placeholder="First Name"
                        v-model="model.name"
                        :error="get_errors('name')"
                        >
                    </base-input>
                    <base-input
                        type="text"
                        placeholder="Last Name"
                        v-model="model.surname"
                        :error="get_errors('surname')"
                        >
                    </base-input>
                    <base-input
                        type="text"
                        placeholder="Organization"
                        v-model="model.organization"
                        :error="get_errors('organization')"
                        >
                    </base-input>
                    <p>Who shared the link to the digital platform with you? Please provide their NAME and EMAIL ADDRESS.</p>
                    <base-textarea
                        placeholder="Name and Email"
                        v-model="model.recommended_by"
                        >
                    </base-textarea>
                    <div class="map-form-agree">
                        <checkbox v-model:checked="model.countering_opposition">
                            I agree with Movement Accelerator Terms of Serivce &amp; Privacy Policy
                        </checkbox>
                        <checkbox v-model:checked="model.social_movement">
                            I Allow IPPF and third parties to control my personal data, for purposes described in the IPPF Privacy Policy
                        </checkbox>
                    </div>
                    <Button type="submit" class="map-color-primary">JOIN</Button>
                </form>
            </template>
            <template v-else>
                <h1 class="map-lineunder">Click here to check other resources from the MAP Platform.</h1>
                <a href="/" class="map-button map-color-primary">Homepage</a>
            </template>
        </div>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';
    import Checkbox from '@/components/Inputs/Checkbox';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    import { defineAsyncComponent } from 'vue';
    import { mapGetters } from 'vuex';

    import { ShareService, UserService } from '@/services';


    export default
    {
        name: 'SharePublic',
        components:
        {
            BlogPost: defineAsyncComponent(() =>
            {
                return import('../Blog/BlogPost.vue')
            }),
            CaseStudyPost: defineAsyncComponent(() =>
            {
                return import('../CaseStudies/CaseStudyPost.vue')
            }),
            Elearning: defineAsyncComponent(() =>
            {
                return import('../E-Learning/CoursePost.vue')
            }),
            LessonPost: defineAsyncComponent(() =>
            {
                return import('../E-Learning/LessonPost.vue')
            }),
            EventPost: defineAsyncComponent(() =>
            {
                return import('../Events/EventPost.vue')
            }),
            NewsPost: defineAsyncComponent(() =>
            {
                return import('../News/NewsPost.vue')
            }),
            ResourcePost: defineAsyncComponent(() =>
            {
                return import('../Resources/ResourcePost.vue')
            }),
            BaseInput,
            BaseTextarea,
            Checkbox,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                loading: false,
                loading_login: false,
                post_type_path: '',
                shared_component: '',
                shared_link: {},
                submitted: false,
                login_model:
                {
                    email: '',
                    password: '',
                },
                model:
                {
                    email: '',
                    name: '',
                    surname: '',
                    organization: '',
                    recommended_by: '',
                    countering_opposition: false,
                    social_movement: false,
                    winning_narratives: false,
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                    name: { required },
                    surname: { required },
                    organization: { required },
                },
            };
        },
        computed:
        {
            ...mapGetters('Auth',
            [
                'authenticating',
                'error'
            ]),

            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async beforeRouteUpdate(to)
        {
            this.loading = true;

            await this.get_shared_link(to.params.lesson);

            this.loading = false;
        },
        async mounted()
        {
            this.loading = true;

            await this.get_shared_link(this.$route.params.lesson);

            this.loading = false;
        },
        methods:
        {
            async get_shared_link(lesson)
            {
                this.loading = true;
                
                this.shared_link = (await ShareService.get(this.$route.params.token)).data;

                if (this.shared_link.already_opened)
                {
                    window.location.href = '/user/login';
                    return ;
                }

                if (lesson)
                {
                    this.shared_link.type = 'App\\Models\\Lesson';
                    this.shared_link.data = this.shared_link.data.lessons[lesson];
                }

                switch (this.shared_link.type)
                {
                    case 'App\\Models\\Blog':
                        this.shared_component = 'BlogPost';
                        this.post_type_path = 'blog';
                        break;

                    case 'App\\Models\\CaseStudy':
                        this.shared_component = 'CaseStudyPost';
                        this.post_type_path = 'case-study';
                        break;

                    case 'App\\Models\\Event':
                        this.shared_component = 'EventPost';
                        this.post_type_path = 'event';
                        break;

                    case 'App\\Models\\Lesson':
                        this.shared_component = 'LessonPost';
                        this.post_type_path = 'e-learning/lesson';
                        break;

                    case 'App\\Models\\Elearning':
                        this.shared_component = 'Elearning';
                        this.post_type_path = 'e-learning';
                        break;

                    case 'App\\Models\\News':
                        this.shared_component = 'NewsPost';
                        this.post_type_path = 'news';
                        break;

                    case 'App\\Models\\Resource':
                        this.shared_component = 'ResourcePost';
                        this.post_type_path = 'resource';
                        break;

                }

                document.body.className = 'post-type-' + (this.shared_link.type.replace('App\\Models\\', '').toLowerCase());

                this.loading = false;
            },

            async signup()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                UserService.signup(this.model).then(() =>
                {
                    this.loading = false;
                    this.$toast.success('User signup was successfully requested!');
                    this.$router.push({ name: 'Login' });

                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }

                    this.loading = false;
                });
            },

            async login()
            {
                this.loading_login = true;

                const response = await this.$store.dispatch('Auth/login',
                {
                    email: this.login_model.email,
                    password: this.login_model.password,
                });

                if (response === true)
                {
                    const path = `/${this.post_type_path}/${this.shared_link.data.id}`;

                    sessionStorage.setItem('redirect', path);
                    
                    this.$router.push('/');
                    location.reload();
                    return ;
                }

                this.loading_login = false;
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    };
</script>
