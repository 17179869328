<template>
    <div class="map-profile-picture"
        :class="{
            'map-dark': dark
        }"
    >
        <img v-bind:src="src || require('@/assets/images/profile.svg')" />
        <slot></slot>
    </div>
</template>

<script>
    export default
    {
        name: 'profile-picture',
        props:
        {
            src:
            {
                type: null,
                default: require('@/assets/images/profile.svg'),
            },
            dark: Boolean,
        },
    }
</script>
