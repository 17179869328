<template>
    <div
        :class="{
            'map-round': round,
            'map-focused': focused,
            'map-error': error,
            'map-success': !error && touched,
            'map-dark': dark,
            'map-has-label': label,
            'map-has-icon': has_icon,
            'map-has-left-icon': iconLeft,
            'map-has-right-icon': iconRight,
        }"
        class="map-input"
        >
        <slot name="label">
            <label v-if="label">{{ label }}</label>
        </slot>
        <div class="map-input-inner">
            <slot name="iconLeft">
                <i class="map-input-icon-left" :class="iconLeft" v-if="iconLeft"></i>
            </slot>
            <slot>
                <input
                    v-model="value"
                    v-bind="$attrs"
                    v-on="listeners"
                    />
            </slot>
            <slot name="iconRight">
                <i class="map-input-icon-right" :class="iconRight" v-if="iconRight"></i>
            </slot>
        </div>
        <slot name="error" v-if="error || $slots.error">
            <p class="map-error">{{ error }}</p>
        </slot>
    </div>
</template>

<script>
    export default
    {
        name: 'base-input',
        // inheritAttrs: false,
        props:
        {
            label: String,
            required: Boolean,
            round: Boolean,
            dark: Boolean,
            modelValue: [String, Number],
            error:
            {
                type: [String, Boolean],
                default: ''
            },
            iconLeft: String,
            iconRight: String,
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                focused: false,
                touched: false
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            has_icon()
            {
                const { iconLeft, iconRight } = this.$slots;

                return (
                    iconLeft !== undefined ||
                    iconRight !== undefined ||
                    this.iconLeft !== undefined ||
                    this.iconRight !== undefined
                );
            },
            listeners()
            {
                return {
                    input: this.on_input,
                    blur: this.on_blur,
                    focus: this.on_focus,
                };
            },
        },
        methods:
        {
            on_input(ev)
            {
                if (!this.touched)
                {
                    this.touched = true;
                }

                this.$emit('update:modelValue', ev.target.value);
            },
            on_focus()
            {
                this.focused = true;
            },
            on_blur()
            {
                this.focused = false;
            },
        }
    }
</script>
