import { createApp } from 'vue';
import App from './App.vue';
import VueMatomo from 'vue-matomo'
import router from './router';
import store from './store';

// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import 'v-calendar/dist/style.css';

import '@/assets/less/style.less';

/**
 *  Plugins
 */
import GlobalDirectives from './plugins/global-directives';
import UserbackPlugin from '@userback/vue';
import Vue3TouchEvents from 'vue3-touch-events';
import VCalendar from 'v-calendar';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import FlagIcon from 'vue-flag-icon';
import VueToast from 'vue-toast-notification';
import AudioVisual from 'vue-audio-visual'
import VueGtag from 'vue-gtag';


import InitLaraberg from './plugins/guttenberg';


document.addEventListener('click', (ev) =>
{
    if (ev.target.closest('.btn-link'))
    {
        const target = document.querySelector(ev.target.dataset.target);

        ev.target.classList.toggle('collapsed');
        target.classList.toggle('show');
    }
});

/**
 *  Services
 */
import { ApiService, TokenService } from '@/services';

ApiService.init(process.env.VUE_APP_BACKEND_URL);

if (TokenService.has_token())
{
    ApiService.set_header();
}

const app = createApp(App);

InitLaraberg(app);

    app.use(store)
        .use(router)
        .use(VueMatomo,
        {
            host: 'https://matomo.movementaccelerator.org/',
            siteId: 1,
        })
        .use(VueGtag,
        {
            config: { id: 'G-YPYFM1HR40' },
        }, router)
        .use(UserbackPlugin,
        {
            token: '37112|74974|TwfjQvT0xKreVc3t76yK7L23z'
        })
        .use(VueToast,
        {
            position: 'top',
            // duration: 1000000,
        })
        .use(Vue3TouchEvents)
        .use(AudioVisual)
        .use(VCalendar)
        .use(FlagIcon)
        .use(PerfectScrollbar)
        .use(GlobalDirectives)
        .mount('#app');

window._paq.push(['trackPageView']); //To track pageview