const wp = window.wp;
const el = wp.element.createElement;

const MediaUpload = wp.blockEditor.MediaUpload;

const media_upload = (media, onSelect) =>
{
    let html = [];

    if (media.url)
    {
        html.push(el('p', {
            src: media.url,
        }));
        html.push(el('a', {
            href: '#',
            style: { display: 'block' },
            onClick: () =>
            {
                if (confirm('Are you sure you want to remove the PDF?'))
                {
                    onSelect({ url: null });
                }
            },
        }, 'Remove pdf'));
    }

    html.push(el(MediaUpload, {
        title: 'PDF Upload',
        // allowedTypes: ['video'],
        value: media.url,
        onSelect: onSelect,
    }));

    return el('div', { }, html);
};

export const PDFBlock = {
    title: 'MAP PDF Block',
    icon: 'format-aside',
    category: 'map',
    attributes: {
        url:
        {
            type: 'string',
            default: '',
        },
        created_at:
        {
            type: 'string',
            default: '',
        },
    },
    edit: (props) =>
    {
        const attrs = props.attributes;

        return el('div', { className: 'pdf-block' },
            media_upload(attrs, (pdf) =>
            {
                props.setAttributes({ url: pdf.url, created_at: pdf.created_at });
            }),
        );
    },

    save: function (props)
    {
        return el('div',
            {
                className: 'pdf-block',
            },
            JSON.stringify(props.attributes)
        );
    },
};

export default PDFBlock;
