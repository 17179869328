const TOKEN_KEY = 'access_token';
const TWOFACTOR_TOKEN_KEY = 'two_factor_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const TokenService =
{
    get_token()
    {
        return localStorage.getItem(TOKEN_KEY);
    },

    has_token()
    {
        return !!this.get_token();
    },

    save_token(token)
    {
        localStorage.setItem(TOKEN_KEY, token);
    },

    remove_token()
    {
        localStorage.removeItem(TOKEN_KEY);
    },

    get_two_factor_token()
    {
        return localStorage.getItem(TWOFACTOR_TOKEN_KEY);
    },

    has_two_factor_token()
    {
        return !!this.get_two_factor_token();
    },

    save_two_factor_token(token)
    {
        localStorage.setItem(TWOFACTOR_TOKEN_KEY, token);
    },

    remove_two_factor_token()
    {
        localStorage.removeItem(TWOFACTOR_TOKEN_KEY);
    },

    get_refresh_token()
    {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    },

    save_refresh_token(token)
    {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    },

    remove_refresh_token()
    {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    },
};

export { TokenService };
