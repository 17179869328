import axios from 'axios';
import { MediaLibrary } from './media-library';

const wp = window.wp;
const el = wp.element.createElement;

const Button = wp.components.Button;

export default function()
{
    const { hooks, element } = window.wp;
    const { Component } = element;

    class MediaUpload extends Component
    {
        constructor(props)
        {
            super(props);

            this.state = {
                media: [],
                is_uploading: false,
                progress: 0,
                upload: 0,
            };
        }

        getMediaType = (path) =>
        {
            const video = ['mp4', 'm4v', 'mov', 'wmv', 'avi', 'mpg', 'ogv', '3gp', '3g2'];
            const audio = ['mp3', 'm4a', 'ogg', 'wav'];
            const extension = path.split('.').slice(-1)
                .pop();

            if (video.includes(extension))
            {
                return 'video';
            }
            else if (audio.includes(extension))
            {
                return 'audio';
            }
            else
            {
                return 'image';
            }
        }

        onSelect = (file) =>
        {
            // this.props.value = null;

            const { multiple, onSelect, addToGallery } = this.props;
            const media = {
                // id: file.url.split('/').pop(),
                url: file.url,
                type: this.getMediaType(file.path),
                created_at: file.created_at,
            };

            if (multiple)
            {
                if (addToGallery)
                {
                    let value = this.state.media || [];

                    if (this.props.value && Array.isArray(this.props.value))
                    {
                        value = this.props.value;
                    }

                    value.push(media);

                    this.state.media = value;
                }
                else
                {
                    this.state.media.push(media);
                }
            }

            onSelect(multiple ? this.state.media : media);
        }

        upload = () =>
        {
            const file_el = document.createElement('input');

            file_el.type = 'file';
            file_el.multiple = this.props.multiple;
            file_el.onchange = (ev) =>
            {
                const files = (ev.target || ev.path[0]).files;

                this.setState({ is_uploading: true });

                this.uploadFiles(files);
            };

            file_el.click();
        }

        createChunks = (file) =>
        {
            // let size = 2097152; // 2MB
            const size = 1024000 * 10; // 80MB
            const chunks_size = Math.ceil(file.size / size);
            const chunks = [];

            for (let i = 0; i < chunks_size; i++)
            {
                chunks.push(file.slice(
                    i * size,
                    Math.min(i * size + size, file.size),
                    file.type
                ));
            }

            return chunks;
        }

        uploadFiles = (files) =>
        {
            files.forEach((file) =>
            {
                let chunks = this.createChunks(file);

                this.uploadChunks(file, chunks);
            });
        }

        uploadChunks = (file, chunks) =>
        {
            const uploadChunk = (chunk) =>
            {
                const is_last = chunks.length < 1;
                let form_data = new FormData();

                form_data.append('is_last', is_last)
                form_data.append(`file`, chunk, `${file.name}.part`);

                axios.post('/api/upload/file', form_data,
                {
                    onUploadProgress: (ev) =>
                    {
                        this.state.upload += ev.loaded;
                        this.setState({ progress: Math.floor((this.state.upload * 100) / file.size) });
                    },
                })
                    .then((response) =>
                    {
                        if (is_last)
                        {
                            this.onSelect(response.data);
                            this.setState({ is_uploading: false });
                        }
                        else
                        {
                            uploadChunk(chunks.shift());
                        }
                        // this.$toast.success('File was successfully uploaded!');
                    })
                    .catch((ex) =>
                    {
                        console.log(ex);
                        // this.$toast.error('Something went wrong. Please try again!');
                    });
            };

            uploadChunk(chunks.shift());
        }

        showMediaLibrary = () =>
        {
            const { multiple } = this.props;

            MediaLibrary.init(multiple);
            MediaLibrary.onchange((file) =>
            {
                this.onSelect(file);
            });
        }

        render()
        {
            let progress_el;

            if (this.state.is_uploading)
            {
                progress_el = el('progress', {
                    max: 100,
                    value: this.state.progress,
                });
            }

            return el(
                'div',
                {},
                el(Button, {
                    variant: 'primary',
                    isDefault: true,
                    onClick: this.upload,
                }, 'Upload'),
                el(Button, {
                    onClick: this.showMediaLibrary,
                }, 'Media Library'),
                progress_el,
            );
        }
    }

    hooks.addFilter(
        'editor.MediaUpload',
        'core/edit-post/components/media-upload/replace-media-upload',
        () => MediaUpload
    );
}
