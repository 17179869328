<template>
    <div class="map-user-notifications" :class="{ 'map-loading': loading }">
        <span class="map-notifications-btn">Notifications ({{ unread_notifications }})</span>
        <perfect-scrollbar class="map-notifications" :options="{ suppressScrollX: true }">
            <loader />
            <ul>
                <li
                v-for="(notification, key) in notifications"
                :key="key"
                :class="{ 'map-notification-read': notification.read }"
                @click="read_notification(notification)"
                >
                    {{ notification.text }}
                </li>
            </ul>
        </perfect-scrollbar>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';

    export default
    {
        name: 'user-notifications',
        components:
        {
            Loader,
        },
        props:
        {
            user:
            {
                type: Object,
                default: undefined,
            },
        },
        data()
        {
            return {
                loading: false,
                // unread_notifications: 0,
                notifications: [],
            };
        },
        computed:
        {
            unread_notifications()
            {
                return this.notifications.filter((item) => !item.read).length;
            },
        },
        async mounted()
        {

        },
        methods:
        {
            async read_notification(notification)
            {
                notification.read = true;

                if (notification.link)
                {
                    this.$router.push(notification.link);
                }
            },
        },
    }
</script>
