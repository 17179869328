import click_outside from '@/directives/click-outside.js';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */
const GlobalDirectives =
{
    install(Vue)
    {
        Vue.directive('click-outside', click_outside);
    }
};

export default GlobalDirectives;
