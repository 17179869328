<template>
    <div class="map-page map-page-post map-page-blog-post">
        <div class="map-page-content map-center-layout" data-section-title="BLOG">
            <h1 class="map-post-title">{{ post.title }}</h1>
            <p class="map-post-summary">{{ post.summary }}</p>
            <ul class="map-post-tags">
                <li v-for="(tag) in post.tags" :key="tag">#{{ tag }}</li>
            </ul>
            <div class="map-post-author">
                <profile-picture :src="get_author_picture(post)"></profile-picture>
                <p>by {{ get_author_name(post) }}</p>
            </div>
            <div class="map-post-content page-dynamic-content" v-html="post.content"></div>
        </div>
        <map-sidebar class="map-blog-post" v-if="post.active_comments">
            <comments
                label="RESPONSES"
                :comments="post.comments"
                :post_type="PostType.BLOG"
                :post_id="$route.params.id"
                >
            </comments>
        </map-sidebar>
    </div>
</template>

<script>
    import ProfilePicture from '@/components/ProfilePicture';
    import Comments from '@/components/Comments';
    import MapSidebar from '@/components/MapSidebar';

    import { GuttenbergContent } from '@/plugins/guttenberg/content';

    import { PostService, PostType } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            ProfilePicture,
            Comments,
            MapSidebar,
        },
        props:
        {
            data: Object,
        },
        setup()
        {
            return {
                PostType: PostType,
            };
        },
        data()
        {
            return {
                loading: false,
                post: {},
            };
        },
        async mounted()
        {
            // const ps_el = document.querySelector('#app > .ps');
            //
            // ps_el.scrollTo(0, 0, 'smooth');

            if (this.data)
            {
                this.post = this.data;
            }
            else
            {
                await this.get_post(this.$route.params.id);
            }

            GuttenbergContent.init();
        },
        methods:
        {
            async get_post(id)
            {
                this.loading = true;

                this.post = (await PostService.get(PostType.BLOG, id)).data;

                this.loading = false;
            },

            get_author_picture(post)
            {
                if (post.author && post.author.image)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + post.author.image.id;
                }
            },

            get_author_name(post)
            {
                if (post.author && post.author.name)
                {
                    return `${post.author.name} ${post.author.surname}`;
                }
            },
        },
    };
</script>
