<template>
    <div
        class="map-podcasts"
        :class="{
            'map-loading': loading,
            'map-podcasts-playing': playing,
        }"
        data-label="Podcasts"
        >
        <loader />
        <audio ref="map-podcasts-audio" :src="audios[current_slide].src" crossorigin="use-credentials"></audio>
        <av-bars
            class="map-av-bars"
            ref-link="map-podcasts-audio"
            canv-width="940"
            canv-height="180"
            :fft-size="2048"
            :bar-width="20"
            :brick-height="4"
            :bar-color="['#ffb882', '#ffb882', '#ea3457']"
            @click="play_pause"
            />
        <footer>
            <div class="map-podcasts-info" data-label="Currently Playing">
                <h2>{{ audios[current_slide].title }}</h2>
                <h3>{{ audios[current_slide].author }} | {{ audios[current_slide].date }}</h3>
            </div>
            <button class="map-button-prev" @click="prev_page">
                <i class="icon-left-arrow-icon"></i>
            </button>
            <button class="map-button-next" @click="next_page">
                <i class="icon-right-arrow-icon"></i>
            </button>
            <span>{{ display_index }}/{{ slide_count || '∞' }}</span>
        </footer>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';

    export default
    {
        name: 'podcasts',
        components:
        {
            Loader,
        },
        props:
        {
            loading:
            {
                type: Boolean,
                default: false,
            },
            audios:
            {
                type: Array,
                default: undefined,
            },
        },
        data()
        {
            return {
                current_slide: 0,
                slide_count: 0,
                playing: false,
            };
        },
        mounted()
        {
            if (this.audios)
            {
                this.slide_count = this.audios.length;
            }
        },
        computed:
        {
            display_index()
            {
                return Math.max(1, Math.min(this.slide_count, this.current_slide + 1));
            },
        },
        watch:
        {
            current_slide()
            {
                this.playing = false;
            },
        },
        methods:
        {
            play_pause()
            {
                const audio_el = this.$refs['map-podcasts-audio'];

                if (!this.playing)
                {
                    audio_el.play();
                }
                else
                {
                    audio_el.pause();
                }

                this.playing = !this.playing;
            },
            prev_page()
            {
                // this.playing = false;
                this.current_slide = Math.max(0, this.current_slide - 1);
            },
            next_page()
            {
                // this.playing = false;
                this.current_slide = Math.min(this.slide_count - 1 , this.current_slide + 1);
            },
        },
    }
</script>
