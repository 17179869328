<template>
    <div
        class="map-video-player"
        :class="{
            'map-loading': loading,
            'map-player-playing': state && state.playing,
            'map-player-paused': state && state.paused,
        }"
        >
        <loader />
        <video-player
            controls
            playsinline
            class="vjs-big-play-centered vjs-big-play-button vjs-paused vjs-controls-enabled vjs-user-active"
            ref="vplayer"
            :src="source"
            :loop="true"
            :volume="0.6"
            @mounted="handle_mounted"
        />
        <div class="map-video-overlay">
            <h1>{{ title }}</h1>
            <span class="map-video-date">{{ format_date() }}</span>
            <p>{{ summary }}</p>
        </div>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import Loader from  '@/components/Loader';
    import { VideoPlayer } from '@videojs-player/vue';

    import 'video.js/dist/video-js.css';

    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';

    export default
    {
        name: 'map-video-player',
        components:
        {
            Loader,
            VideoPlayer,
        },
        props:
        {
            loading: Boolean,
            src: String,
            poster: String,
            title: String,
            date: String,
            summary: String,
        },
        setup()
        {
            const player = shallowRef();
            const state = shallowRef();
            const handle_mounted = (payload) =>
            {
                player.value = payload.player
                state.value = payload.state;
            };

            return { player, state, handle_mounted }
        },
        data()
        {
            return {
                playing: false,
                options:
                {
                    muted: true,
                    aspectRatio: '4:3',
                    fluid: true,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [],
                },
                source:
                {
                    src: '',
                    type: 'video/mp4'
                },
            };
        },
        watch:
        {
            src()
            {
                this.source.src = this.src;
            },
        },
        mounted()
        {
            this.source.src = this.src;
        },
        unmounted()
        {
        },
        methods:
        {
            format_date()
            {
                dayjs.extend(customParseFormat);

                let date = this.date;

                if (this.date)
                {
                    date = this.date.replace('T', ' ');
                }

                return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('MMMM D, YYYY');
                // return dayjs(this.date, 'DD/MM/YYYY hh:mm A').format('MMMM D, YYYY');
            },
        },
    }
</script>
