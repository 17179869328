<template>
    <div class="map-page-login map-center-layout" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <h1 class="map-lineunder">Reset Password</h1>
            <loader />
            <form @submit.prevent="reset">
                <base-input
                    placeholder="Password"
                    type="password"
                    v-model="model.password"
                    :error="get_errors('password')"
                    >
                </base-input>
                <base-input
                    placeholder="Confirm Password"
                    type="password"
                    v-model="model.password_confirmation"
                    :error="get_errors('password_confirmation')"
                    >
                </base-input>
                <Button type="submit" class="map-color-primary">RESET</Button>
                <router-link to="/user/login" class="icph-label-button">Sign In</router-link>
                <span>&nbsp;|&nbsp;</span>
                <a href="mailto:support_movementaccelerator@ippf.org" target="_blank">Contact Us</a>
            </form>
        </div>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import { helpers, required, email, minLength } from '@vuelidate/validators';
    import { AuthService } from '@/services';

    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    export default
    {
        name: 'Login',
        components:
        {
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                mail_sent: false,
                response: '',
                error: '',
                model:
                {
                    email: this.$route.params.email,
                    token: this.$route.params.token,
                    password: '',
                    password_confirmation: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                    password: { required, minLength: minLength(6) },
                    password_confirmation: {
                        sameAsPass: helpers.withMessage('Confirm password should be the same as password', () => this.model.password === this.model.password_confirmation)
                    },
                },
            };
        },
        methods:
        {
            async reset()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                await AuthService.submit_reset_password(this.model);

                this.loading = false;

                this.$router.push({ name: 'Login' });
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    };
</script>
