<template>
    <header class="main-header"
        :class="{
            'map-only-logo': onlyLogo,
            'map-sticky': sticky
        }">
        <perfect-scrollbar :options="{ suppressScrollX: true }">
            <div class="map-logo-holder">
                <router-link to="/" class="map-logo" @click="this.menu_expanded = false"></router-link>
                <button
                    type="button"
                    class="map-open-menu"
                    @click="toggle_menu"
                    >
                </button>
            </div>
            <div
                class="map-main-menu-holder"
                :class="{'map-expanded': menu_expanded}"
                >
                <base-input
                    class="map-search-input"
                    :round="true"
                    icon-left="icon-search"
                    placeholder="Search..."
                    v-model="search_term"
                    @change="do_search"
                    >
                </base-input>
                <nav class="map-main-menu">
                    <router-link to="/blog" class="map-menu-item map-item-blog" style="opacity: .5" v-if="user.role === 'super-admin'" @click="this.menu_expanded = false"><span>Blog Archive</span></router-link>
                    <!-- <router-link to="/news" class="map-menu-item map-item-news" @click="this.menu_expanded = false"><span>News</span></router-link> -->
                    <router-link to="/events" class="map-menu-item map-item-events" style="opacity: .5"  v-if="user.role === 'super-admin'" @click="this.menu_expanded = false"><span>Events Archive</span></router-link>
                    <router-link to="/e-learning" class="map-menu-item map-item-e-learning" @click="this.menu_expanded = false"><span>E-Learning</span></router-link>
                    <router-link to="/resources" class="map-menu-item map-item-resources" @click="this.menu_expanded = false"><span>Resources</span></router-link>
                    <router-link to="/case-studies" class="map-menu-item map-item-case-studies" @click="this.menu_expanded = false"><span>Case Studies</span></router-link>
                    <!-- <router-link to="/forum" class="map-menu-item map-item-forum" @click="this.menu_expanded = false"><span>Forum</span></router-link> -->
                    <router-link to="/contact" class="map-menu-item map-item-contact" @click="this.menu_expanded = false"><span>Contact</span></router-link>
                </nav>
                <!-- <nav class="map-centers-menu">
                    <p>Discover<br />the Centres<br />-</p>
                    <router-link :to="{ name: 'Social Movement' }" class="map-menu-item map-item-contact" @click="this.menu_expanded = false"><span>Social Movement</span></router-link>
                    <router-link :to="{ name: 'Countering Opposition' }" class="map-menu-item map-item-contact" @click="this.menu_expanded = false"><span>Countering Opposition</span></router-link>
                    <router-link :to="{ name: 'Winning Narratives' }" class="map-menu-item map-item-contact" @click="this.menu_expanded = false"><span>Winning Narratives</span></router-link>
                </nav> -->
                <router-link :to="{ name: 'UserProfile' }" class="map-profile">
                    <p>Welcome<b>{{ user.name }} {{ user.surname }}</b></p>
                    <profile-picture :src="get_user_picture()"></profile-picture>
                </router-link>
                <div class="map-copyright-menu">
                    <p class="map-copyright">&copy; IPPF {{ year }}</p>
                    <nav class="map-menu">
                        <a class="map-menu-item">Terms of Use</a>
                        <a class="map-menu-item">Privacy Policy</a>
                    </nav>
                </div>
            </div>
        </perfect-scrollbar>
    </header>
</template>

<script>
    import ProfilePicture from '@/components/ProfilePicture';
    import BaseInput from '@/components/Inputs/BaseInput';

    export default
    {
        name: 'Header',
        components:
        {
            ProfilePicture,
            BaseInput,
        },
        props: {
            onlyLogo: {
                type: Boolean,
                default: false
            },
            sticky: {
                type: Boolean,
                default: false
            }
        },
        data()
        {
            return {
                year: new Date().getFullYear(),
                menu_expanded: false,
                search_term: this.$route.params.query || '',
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
            can_see()
            {
                const role = this.user.role;

                return (
                    role === 'super-admin' ||
                    role === 'admin' ||
                    role === 'global-team'
                );
            },
        },
        mounted()
        {
            // this.user = this.$store.getters['Auth/user'];

        },
        methods:
        {
            toggle_menu()
            {
                this.menu_expanded = !this.menu_expanded;
            },

            get_user_picture()
            {
                return (this.user.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.user.profile_picture_id : 0)
            },

            do_search()
            {
                this.$router.push({ name: 'Search', params: { query: this.search_term } });
            },
        },
    }
</script>
