<template>
    <div class="map-page-signup map-center-layout" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <h1 class="map-lineunder">Sign Up</h1>
            <p>Create Your MAP Account</p>
            <p>NOTE: For security reasons, please use an email address that includes your employing organisation's domain name.</p>
            <p>By filling in this form, you consent to the MAP team sending you regular email updates about new content on the MAP online platform. You can unsubscribe from those emails at any point by emailing Support_MovementAccelerator@ippf.org.</p>
            <p>Please complete all the fields below and click on ‘Submit’. We'll then be in touch again once we have validated your account. Your account will be validated immediately if you use an IPPF-related email domain.</p>
            <loader />
            <form @submit.prevent="signup">
                <base-input
                    type="email"
                    placeholder="Email"
                    v-model="model.email"
                    :error="get_errors('email')"
                    >
                </base-input>
                <base-input
                    type="text"
                    placeholder="First Name"
                    v-model="model.name"
                    :error="get_errors('name')"
                    >
                </base-input>
                <base-input
                    type="text"
                    placeholder="Last Name"
                    v-model="model.surname"
                    :error="get_errors('surname')"
                    >
                </base-input>
                <base-input
                    type="text"
                    placeholder="Organization"
                    v-model="model.organization"
                    :error="get_errors('organization')"
                    >
                </base-input>
                <!-- <p>Which learning centre are you interested in? Tick all that apply.</p>
                <div class="map-center-list">
                    <checkbox v-model:checked="model.countering_opposition">
                        Countering Opposition
                    </checkbox>
                    <checkbox v-model:checked="model.social_movement">
                        Social Movement
                    </checkbox>
                    <checkbox v-model:checked="model.winning_narratives">
                        Winning Narratives
                    </checkbox>
                </div> -->
                <p>Who shared the link to the digital platform with you? Please provide their NAME and EMAIL ADDRESS.</p>
                <base-textarea
                    placeholder="Name and Email"
                    v-model="model.recommended_by"
                    >
                </base-textarea>
                <Button type="submit" class="map-color-primary">SIGNUP</Button>
                <router-link to="/user/login" class="icph-label-button">Sign In</router-link>
                <span>&nbsp;|&nbsp;</span>
                <a href="mailto:support_movementaccelerator@ippf.org" target="_blank">Contact Us</a>
            </form>
        </div>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';
    // import Checkbox from '@/components/Inputs/Checkbox';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    import { UserService } from '@/services';


    export default
    {
        name: 'Login',
        components:
        {
            BaseInput,
            BaseTextarea,
            // Checkbox,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                model:
                {
                    email: '',
                    name: '',
                    surname: '',
                    organization: '',
                    recommended_by: '',
                    countering_opposition: false,
                    social_movement: false,
                    winning_narratives: false,
                },
                lang_captions:
                {
                    gb: 'English',
                    fr: 'Français',
                    es: 'Español',
                    ae: 'Arabic',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                    name: { required },
                    surname: { required },
                    organization: { required },
                },
            };
        },
        computed:
        {
        },
        methods:
        {
            async signup()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                UserService.signup(this.model).then(() =>
                {
                    this.loading = false;
                    this.$toast.success('User signup was successfully requested!');
                    this.$router.push({ name: 'Login' });

                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }

                    this.loading = false;
                });
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    };
</script>
