import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';
import { TokenService } from '@/services';

import MainLayout from '../layout/MainLayout.vue';
// import AdminLayout from '../layout/AdminLayout.vue';
import AuthLayout from '../layout/AuthLayout.vue';
import ShareLayout from '../layout/ShareLayout.vue';

const AdminLayout = MainLayout;

import Welcome from '../pages/Welcome.vue';

import Login from '../pages/Auth/Login.vue';
import SignUp from '../pages/Auth/SignUp.vue';
import TwoFactor from '../pages/Auth/TwoFactor.vue';
import RecoverPassword from '../pages/Auth/RecoverPassword.vue';
import ResetPassword from '../pages/Auth/ResetPassword.vue';

import UserProfile from '../pages/UserProfile.vue';

import Dashboard from '../pages/Dashboard.vue';
import About from '../pages/About.vue';
import Centre from '../pages/Centre.vue';

import SharePublic from '../pages/Share/SharePublic.vue';

import Blog from '../pages/Blog/Blog.vue';
import BlogPost from '../pages/Blog/BlogPost.vue';

import News from '../pages/News/News.vue';
import NewsPost from '../pages/News/NewsPost.vue';

import Events from '../pages/Events/Events.vue';
import EventPost from '../pages/Events/EventPost.vue';

import ELearning from '../pages/E-Learning/E-Learning.vue';
import CoursePost from '../pages/E-Learning/CoursePost.vue';
import LessonPost from '../pages/E-Learning/LessonPost.vue';

import Resources from '../pages/Resources/Resources.vue';
import ResourcePost from '../pages/Resources/ResourcePost.vue';

import CaseStudies from '../pages/CaseStudies/CaseStudies.vue';
import CaseStudyPost from '../pages/CaseStudies/CaseStudyPost.vue';
import Contact from '../pages/Contact.vue';

import Search from '../pages/Search.vue';
// import Home from '../views/Home.vue';


// Admin
// import UsersList from '../pages/Admin/Users/List';

const routes = [{
    path: '/user',
    name: 'Authentication',
    component: AuthLayout,
    redirect: '/user/login',
    children:
    [
        {
            path: 'login',
            name: 'Login',
            component: Login,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'signup',
            name: 'SignUp',
            component: SignUp,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'recover',
            name: 'RecoverPassword',
            component: RecoverPassword,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'reset-password/:token/:email',
            name: 'ResetPassword',
            component: ResetPassword,
            meta:
            {
                public: true,
                only_when_logged_out: true
            },
        },
        {
            path: 'two-factor',
            name: 'TwoFactor',
            component: TwoFactor,
            meta:
            {
                public: false,
                only_when_logged_out: false
            },
        },
        {
            path: 'logout',
            name: 'Logout',
            component:
            {
                beforeRouteEnter()
                {
                    store.dispatch('Auth/logout');
                },
            },
        },
    ],
},
{
    path: '/',
    name: 'Dashboard',
    component: MainLayout,
    children:
    [
        {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
        },
        {
            path: '/about-map',
            name: 'About',
            component: About,
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome,
            meta:
            {
                public: true,
                only_when_logged_out: true,
                has_footer: true,
            },
        },
        {
            path: '/user/profile',
            name: 'UserProfile',
            component: UserProfile,
        },
        {
            path: '/user/profile/edit',
            name: 'UserProfileEdit',
            component: () => import(/* webpackChunkName: "admin.users.create" */ '../pages/Admin/Users/Create.vue'),
            meta:
            {
                user_profile: true,
            },
        },
        {
            path: '/centre/',
            name: 'Centers',
            component: Centre,
            children:
            [
                {
                    path: 'social-movement',
                    name: 'Social Movement',
                    component: Centre,
                    meta:
                    {
                        centre_image: require('@/assets/images/mobile/social-movement.svg'),
                    },
                },
                {
                    path: 'winning-narratives',
                    name: 'Winning Narratives',
                    component: Centre,
                    meta:
                    {
                        centre_image: require('@/assets/images/mobile/winning-narratives.svg'),
                    },
                },
                {
                    path: 'countering-opposition',
                    name: 'Countering Opposition',
                    component: Centre,
                    meta:
                    {
                        centre_image: require('@/assets/images/mobile/countering-opposition.svg'),
                    },
                },
            ],
        },
        {
            path: '/blog/',
            name: 'Blog',
            component: Blog,
        },
        {
            path: '/blog/:id',
            name: 'Blog Post',
            component: BlogPost,
        },
        {
            path: '/news/',
            name: 'News',
            component: News,
        },
        {
            path: '/news/:id',
            name: 'News Post',
            component: NewsPost,
        },
        {
            path: '/events/',
            name: 'Events',
            component: Events,
        },
        {
            path: '/event/:id',
            name: 'Event Post',
            component: EventPost,
        },
        {
            path: '/e-learning/',
            name: 'E-Learning',
            component: ELearning,
        },
        {
            path: '/e-learning/course/:id',
            name: 'CoursePost',
            component: CoursePost,
        },
        {
            path: '/e-learning/lesson/:id',
            name: 'E-Learning Lesson',
            component: LessonPost,
        },
        {
            path: '/resources/',
            name: 'Resources',
            component: Resources,
        },
        {
            path: '/resource/:id',
            name: 'Resource Post',
            component: ResourcePost,
        },
        {
            path: '/case-studies/',
            name: 'CaseStudies',
            component: CaseStudies,
        },
        {
            path: '/case-study/:id',
            name: 'CaseStudy',
            component: CaseStudyPost,
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact,
        },
        {
            path: '/search/:query',
            name: 'Search',
            component: Search,
        },
    ],
},
{
    path: '/shared',
    name: 'Shared',
    component: ShareLayout,
    children:
    [
        {
            path: 'public/:token/:lesson?',
            name: 'SharePublic',
            component: SharePublic,
            meta:
            {
                public: true,
            },
        },
        {
            path: 'one-time/:token/:lesson?',
            name: 'ShareOneTime',
            component: SharePublic,
            meta:
            {
                public: true,
            },
        },
    ],
},
{
    path: '/admin/',
    name: 'Admin',
    component: AdminLayout,
    meta:
    {
        authorize: ['super-admin', 'admin'],
        layout_class: 'admin-layout',
    },
    children:
    [
        {
            path: 'users',
            name: 'UsersList',
            component: () => import(/* webpackChunkName: "admin.users" */ '../pages/Admin/Users/List.vue'),
            meta:
            {
            },
        },
        {
            path: 'users/create',
            name: 'UsersCreate',
            component: () => import(/* webpackChunkName: "admin.users.create" */ '../pages/Admin/Users/Create.vue'),
            meta:
            {
                authorize: ['super-admin'],
            },
        },
        {
            path: 'users/:id/edit',
            name: 'UsersEdit',
            component: () => import(/* webpackChunkName: "admin.users.edit" */ '../pages/Admin/Users/Create.vue'),
            meta:
            {
                authorize: ['super-admin'],
            },
        },


        {
            path: 'posts',
            name: 'PostsList',
            component: () => import(/* webpackChunkName: "admin.posts" */ '../pages/Admin/Posts/List.vue'),
            meta:
            {
            },
        },
        {
            path: ':type/create',
            name: 'PostsCreate',
            component: () => import(/* webpackChunkName: "admin.posts.create" */ '../pages/Admin/Posts/Create.vue'),
            meta:
            {
                authorize: ['super-admin'],
            },
        },
        {
            path: ':type/:id/edit',
            name: 'PostsEdit',
            component: () => import(/* webpackChunkName: "admin.posts.edit" */ '../pages/Admin/Posts/Create.vue'),
            meta:
            {
                authorize: ['super-admin'],
            },
        },

        {
            path: 'centers',
            name: 'CentersList',
            component: () => import(/* webpackChunkName: "admin.centers" */ '../pages/Admin/Centers/List.vue'),
            meta:
            {
            },
        },
        {
            path: 'centers/:id/edit',
            name: 'CentersEdit',
            component: () => import(/* webpackChunkName: "admin.centers.edit" */ '../pages/Admin/Centers/Edit.vue'),
            meta:
            {
                authorize: ['super-admin'],
            },
        },

        {
            path: 'tags-and-topics',
            name: 'TopicsTagsList',
            component: () => import(/* webpackChunkName: "admin.topics-tags" */ '../pages/Admin/TopicsTags/List.vue'),
            meta:
            {
            },
        },

        {
            path: 'countries',
            name: 'CountriesList',
            component: () => import(/* webpackChunkName: "admin.countries" */ '../pages/Admin/Countries/List.vue'),
            meta:
            {
            },
        },
        {
            path: 'countries/:id/edit',
            name: 'CountriesEdit',
            component: () => import(/* webpackChunkName: "admin.countries.edit" */ '../pages/Admin/Countries/Edit.vue'),
            meta:
            {
                authorize: ['super-admin'],
            },
        },
    ],
}];

const router = createRouter(
{
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) =>
{
    const is_public = to.matched.some(record => record.meta.public);
    const only_when_logged_out = to.matched.some(record => record.meta.only_when_logged_out);
    const logged_in = TokenService.has_token();
    const two_factor_validated = TokenService.has_two_factor_token();

    if (!is_public && !logged_in)
    {
        sessionStorage.setItem('redirect', to.path);

        return next({
            path: '/welcome',
        });
        // return next({
        //     path: '/user/login',
        // });
    }

    if ((!to.meta || to.meta.only_when_logged_out !== false) && logged_in && only_when_logged_out)
    {
        return next('/');
    }

    if (logged_in)
    {
        let initializations = [];

        initializations.push(store.dispatch('Auth/init'));

        Promise.all(initializations).then(function ()
        {
            const logged_user = store.getters['Auth/user'];
            const two_factor_path = '/user/two-factor';
            const new_pass_path = '/user/new-passwod';
            const logout_path = '/user/logout';
            const welcome_path = '/dashboard';
            const two_factor_allowed_paths = [logout_path, two_factor_path];

            if (!two_factor_validated && two_factor_allowed_paths.indexOf(to.path) === -1)
            {
                return next(two_factor_path);
            }

            if (logged_user.requre_new_password && [new_pass_path, logout_path].indexOf(to.path) === -1)
            {
                return next(new_pass_path);
            }

            if (to.meta.authorize && to.meta.authorize.indexOf(logged_user.role) === -1)
            {
                return next('/');
            }

            if ((logged_user.status === null || logged_user.status === 'NEW') && [new_pass_path, logout_path, welcome_path, two_factor_path].indexOf(to.path) === -1)
            {
                // return next(welcome_path);
            }

            next();
        });
    }
    else
    {
        next();
    }
});

export default router;
