import { ApiService } from './api-service';

const CountryService =
{
    all(params)
    {
        return ApiService.get(`/api/countries`, params);
    },

    get(id)
    {
        return ApiService.get(`/api/countries/${id}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/countries/${id}`, data);
    },
};

export { CountryService };
