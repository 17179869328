import axios from 'axios';
import { Utils } from '@/helpers/utils';

// const data =
// {
//     page: 1,
//     page_count: 1,
//     files: [],
// };

export const MediaLibrary = {
    page: 1,
    last_page: 1,

    /**
     *  Initialize.
     */
    init(multiple)
    {
        this.dom_el = this.create(document.querySelector('body'));
        this.multiple = multiple;
        this.page = 1;
        this.last_page = 1;
        this.uploaded = 0;
        this.progress = 0;

        return this.dom_el;
    },

    /**
     *  Set on change callback.
     *
     *  @param Function cb
     */
    onchange(cb)
    {
        this.onchange_cb = cb;
    },

    /**
     *  Create DOM structure.
     *
     *  @param DOMElement parent_el
     */
    create(parent_el)
    {
        const el = document.createElement('div');

        el.className = 'media-library';

        const inner_el = document.createElement('div');

        inner_el.appendChild(this.create_header());
        inner_el.appendChild(this.create_file_list());
        inner_el.appendChild(this.create_footer());

        el.appendChild(inner_el);
        parent_el.appendChild(el);

        this.parent_el = parent_el;

        return el;
    },

    /**
     *  Create header.
     *
     *  @return DOMElement
     */
    create_header()
    {
        const el = document.createElement('header');

        el.className = 'media-library-header';
        el.innerHTML = '<h1>Media Library</h1>';

        const progress_el = document.createElement('progress');

        progress_el.max = 100;
        progress_el.value = 0;

        el.appendChild(progress_el);

        this.progress_el = progress_el;

        return el;
    },

    /**
     *  Create header.
     *
     *  @return DOMElement
     */
    create_file_list()
    {
        const el = document.createElement('ul');

        el.className = 'media-library-list';

        el.addEventListener('click', (ev) =>
        {
            const target = ev.target.closest('li');

            if (target)
            {
                target.classList.toggle('selected');

                this.selected = JSON.parse(target.dataset.file);
            }
        }, false);

        el.addEventListener('scroll', () =>
        {
            if (this.page >= this.last_page)
            {
                return ;
            }

            if (Utils.isAtBottomOfElement(el))
            {
                this.loadNextPage();
            }
        }, false);

        this.loadNextPage();

        this.file_list_el = el;

        return el;
    },

    /**
     *  Create header.
     *
     *  @return DOMElement
     */
    create_footer()
    {
        const el = document.createElement('footer');

        el.className = 'media-library-footer';
        el.innerHTML = '<input type="file" class="ml-upload-file" />' +
            '<button class="upload-button map-button">UPLOAD</button>' +
            '<button class="cancel-button map-button">CANCEL</button>' +
            '<button class="select-button map-button map-color-primary">SELECT</button>';

        el.addEventListener('click', (ev) =>
        {
            if (ev.target.classList.contains('cancel-button'))
            {
                this.destroy();
            }
            else if (ev.target.classList.contains('select-button'))
            {
                if (this.multiple)
                {
                    this.dom_el.querySelectorAll('.selected').forEach((el) =>
                    {
                        this.onchange_cb(JSON.parse(el.dataset.file));
                    });
                }
                else
                {
                    this.onchange_cb(this.selected);
                }

                this.destroy();
            }
            else if (ev.target.classList.contains('upload-button'))
            {
                el.querySelector('.ml-upload-file').click();
            }
        }, false);

        el.querySelector('.ml-upload-file').onchange = (ev) =>
        {
            const files = (ev.target || ev.path[0]).files;

            this.uploadFiles(files);
        };

        return el;
    },

    /**
     *  Remove from DOM.
     */
    destroy()
    {
        if (this.dom_el)
        {
            this.dom_el.remove();
        }
    },

    loadNextPage()
    {
        axios.get('/api/files?type=image&page=' + this.page).then((response) =>
        {
            const files = response.data.data.map((file) =>
            {
                const url = process.env.VUE_APP_BACKEND_URL + 'api/files/' + file.id;
                const name = file.filename;
                const type = file.mime_type || file.type;

                if (!~type.indexOf('image'))
                {
                    return '';
                }

                file.url = url;

                const file_string = JSON.stringify(file);

                return `<li data-file='${file_string}' title="${name}">
                    <img src="${url}/200x_" />
                    <p class="map-ml-title" title="${name}">${name}</p>
                </li>
                `;
            });

            // this.file_list_el.innerHTML = this.file_list_el.innerHTML + files.join(' ');
            this.file_list_el.insertAdjacentHTML('beforeend', files.join(' '));


            this.last_page = response.data.last_page;
        });

        this.page++;
    },

    createChunks(file)
    {
        // let size = 2097152; // 2MB
        const size = 1024000 * 10; // 80MB
        const chunks_size = Math.ceil(file.size / size);
        const chunks = [];

        for (let i = 0; i < chunks_size; i++)
        {
            chunks.push(file.slice(
                i * size,
                Math.min(i * size + size, file.size),
                file.type
            ));
        }

        return chunks;
    },

    uploadFiles(files)
    {
        files.forEach((file) =>
        {
            let chunks = this.createChunks(file);

            this.uploadChunks(file, chunks);
        });
    },

    uploadChunks(file, chunks)
    {
        const uploadChunk = (chunk) =>
        {
            const is_last = chunks.length <= 1;
            let form_data = new FormData();

            form_data.append('is_last', is_last)
            form_data.append(`file`, chunk, `${file.name}.part`);

            axios.post('/api/upload/file', form_data,
            {
                onUploadProgress: (ev) =>
                {
                    this.uploaded += ev.loaded;

                    this.progress_el.value = Math.floor((this.uploaded * 100) / file.size);
                },
            })
                .then((response) =>
                {
                    if (is_last)
                    {
                        const file = response.data;
                        const url = file.url;
                        const name = file.path;

                        const file_string = JSON.stringify(file);

                        this.file_list_el.insertAdjacentHTML('afterbegin', `<li data-file='${file_string}'>
                            <img src="${url}" />
                            <span>${name}</span>
                        </li>
                        `);

                        this.file_list_el.scrollTo(0, 0);

                        setTimeout(() => this.progress_el.value = 0, 500);
                    }
                    else
                    {
                        uploadChunk(chunks.shift());
                    }
                    // this.$toast.success('File was successfully uploaded!');
                })
                .catch((ex) =>
                {
                    console.log(ex);
                    // this.$toast.error('Something went wrong. Please try again!');
                });
        };

        uploadChunk(chunks.shift());
    },

    // uploadFile(files)
    // {
    //     let form_data = new FormData();
    //
    //     files.forEach((file, key) =>
    //     {
    //         form_data.append(`files[${key}]`, file);
    //     });
    //
    //     axios.post('/api/upload/files', form_data)
    //         .then((response) =>
    //         {
    //             response.data.forEach((file) =>
    //             {
    //                 const url = file.url;
    //                 const name = file.path;
    //
    //                 const file_string = JSON.stringify(file);
    //
    //                 this.file_list_el.insertAdjacentHTML('afterbegin', `<li data-file='${file_string}'>
    //                     <img src="${url}" />
    //                     <span>${name}</span>
    //                 </li>
    //                 `);
    //
    //                 this.file_list_el.scrollTo(0, 0);
    //             });
    //
    //             // this.$toast.success('File was successfully uploaded!');
    //         })
    //         .catch(() =>
    //         {
    //             // this.$toast.error('Something went wrong. Please try again!');
    //         });
    // },
};

export default { MediaLibrary };
