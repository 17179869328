<template>
    <div class="map-video-gallery">
        <video-player
            :src="videos[active_index].url"
            :title="videos[active_index].title"
            :date="videos[active_index].created_at"
            :summary="videos[active_index].summary"
        />
        <ul class="map-video-playlist">
            <li
                :class="{
                    'map-active': key === active_index,
                }"
                v-for="(video, key) in videos"
                :key="'vpl_' + key"
                @click="active_index = key"
                >
                <h2>{{ video.title }}</h2>
                <p>{{ video.summary }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
    import VideoPlayer from '@/components/VideoPlayer';

    export default
    {
        name: 'video-gallery',
        components:
        {
            VideoPlayer,
        },
        props:
        {
            videos: Object,
        },
        data()
        {
            return {
                active_index: 0,
            };
        },
        mounted()
        {
        },
        unmounted()
        {
        },
        methods:
        {
        },
    }
</script>
