import { ApiService } from './api-service';

const CommentService =
{
    all(params)
    {
        return ApiService.get('/api/comments', params);
    },

    get(id)
    {
        return ApiService.get(`/api/comments/${id}`);
    },

    create(data)
    {
        return ApiService.post('/api/comments', data);
    },

    reply(id, data)
    {
        return ApiService.post(`/api/comments/${id}`, data);
    },

    remove(id)
    {
        return ApiService.delete(`/api/comments/${id}`);
    },
};

export { CommentService };
