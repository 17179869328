import { AuthService, AuthenticationError, TokenService, UserService } from '@/services'
import router from '@/router';

const state =
{
    authenticating: false,
    access_token: TokenService.get_token(),
    two_factor: TokenService.get_two_factor_token(),
    error: '',
    logged_user: false,
};

const getters =
{
    logged_in: (state) =>
    {
        return !!state.access_token;
    },

    error: (state) =>
    {
        return state.error;
    },

    authenticating: (state) =>
    {
        return state.authenticating;
    },

    user: (state) =>
    {
        return state.logged_user;
    },
};

const actions =
{
    async init({commit, dispatch})
    {
        await UserService.get_user().then((response) =>
        {
            commit('INIT_SUCCESS', response.data);
        }).catch(() =>
        {
            dispatch('logout');
        });
    },

    async await_check({dispatch})
    {
        if (!state.logged_user)
        {
            await dispatch('init');
        }
    },

    async login({commit, dispatch}, {email, password})
    {
        commit('LOGIN_REQUEST');

        try
        {
            commit('LOGIN_SUCCESS', await AuthService.login(email, password));

            await dispatch('init');

            router.push(sessionStorage.getItem('redirect') || '/');

            sessionStorage.removeItem('redirect');

            return true;
        }
        catch (ex)
        {
            if (ex instanceof AuthenticationError)
            {
                commit('LOGIN_ERROR', { error_code: ex.error_code, message: ex.message });
            }

            return false;
        }
    },

    async login_two_factor({commit, dispatch}, {code})
    {
        commit('TWO_FACTOR_LOGIN_REQUEST');

        try
        {
            commit('TWO_FACTOR_LOGIN_SUCCESS', await AuthService.login_two_factor(code));

            await dispatch('init');

            router.push(sessionStorage.getItem('redirect') || '/');

            sessionStorage.removeItem('redirect');

            return true;
        }
        catch (ex)
        {
            if (ex instanceof AuthenticationError)
            {
                commit('TWO_FACTOR_LOGIN_ERROR', { error_code: ex.error_code, message: ex.message });
            }

            return false;
        }
    },

    async logout({commit})
    {
        await AuthService.logout();
        commit('LOGOUT_SUCCESS');
        router.push('/welcome');
    },
};

const mutations =
{
    INIT_SUCCESS(state, data)
    {
        state.logged_user = data;
    },

    LOGIN_REQUEST(state)
    {
        state.authenticating = true;
        state.error = '';
    },

    LOGIN_SUCCESS(state, token)
    {
        state.access_token = token;
        state.authenticating = false;
    },

    LOGIN_ERROR(state, {message})
    {
        state.authenticating = false;
        state.error = message;
    },

    TWO_FACTOR_LOGIN_REQUEST(state)
    {
        state.authenticating = true;
        state.error = '';
    },

    TWO_FACTOR_LOGIN_SUCCESS(state, token)
    {
        state.two_factor = token;
        state.authenticating = false;
    },

    TWO_FACTOR_LOGIN_ERROR(state, {message})
    {
        state.authenticating = false;
        state.error = message;
    },

    LOGOUT_SUCCESS(state)
    {
        state.access_token = '';
        state.logged_user = false;
    },
};

export const Auth =
{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
