<template>
    <div class="map-page map-page-centre">
        <div class="map-page-content map-center-layout">
            <img :src="$route.meta.centre_image" />
            <div class="page-dynamic-content" v-html="center.content"></div>
        </div>
        <map-sidebar class="map-sidebar-center">
            <articles class="map-center-spotlight" label="In the Spotlight" :single="true">
                <post
                    v-if="center.spotlight"
                    :image="center.spotlight.cover_image"
                    :title="center.spotlight.title"
                    :date="center.spotlight.created_at"
                    :excerpt="center.spotlight.summary"
                    :link="get_spotlight_link(center)"
                    >
                </post>
            </articles>
            <articles class="map-center-events" label="Events" :slider="true" :posts="events" type="event">
            </articles>
            <articles class="map-center-news" label="Latest News" :slider="true" :posts="blogs" type="blog">
            </articles>
        </map-sidebar>
    </div>
</template>

<script>
    import Articles from '@/components/Articles/Articles';
    import Post from '@/components/Articles/Post';
    import MapSidebar from '@/components/MapSidebar';

    import { CenterService, PostService, PostType } from '@/services';

    export default
    {
        name: 'Centre',
        components:
        {
            Articles,
            Post,
            MapSidebar,
        },
        data()
        {
            return {
                loading: false,
                center_name: this.$route.name,
                blogs: [],
                events: [],
                centers: [],
                center: {},
            };
        },
        async beforeRouteUpdate(to)
        {
            // if (window.location.hash === '#' || window.location.hash === '')
            // {
            //     document.querySelector('#app > .ps').scrollTo(0, 0, 'smooth');
            // }

            this.has_centers = this.centers && this.centers.length;

            this.center = this.centers.filter((center) => center.name === to.name).pop();
        },
        async mounted()
        {
            this.loading = true;

            await this.get_centers();
            await this.get_blogs();
            await this.get_events();

            this.loading = false;
        },
        methods:
        {
            async get_centers()
            {
                this.loading = true;

                this.centers = (await CenterService.all()).data;

                this.has_centers = this.centers && this.centers.length;

                this.center = this.centers.filter((center) => center.name === this.center_name).pop();

                this.loading = false;
            },

            async get_blogs()
            {
                const response = (await PostService.all(PostType.BLOG, {
                    page: this.page,
                    center: this.center.id,
                    'per-page': 10,
                    'order-by': 'published_at',
                })).data;

                this.blogs = response.data;
            },

            async get_events()
            {
                const response = (await PostService.all(PostType.EVENT, {
                    page: this.page,
                    center: this.center.id,
                    filter: 'upcoming',
                    'per-page': 10,
                    'order-by': 'published_at',
                })).data;

                this.events = response.data;
            },

            get_spotlight_link(center)
            {
                let type_link = '/';

                switch (center.spotlight_type)
                {
                    case 'App\\Models\\Blog':
                        type_link += 'blog';
                        break;
                    case 'App\\Models\\Event':
                        type_link += 'event';
                        break;
                    case 'App\\Models\\Resource':
                        type_link += 'resource';
                        break;
                    case 'App\\Models\\CaseStudy':
                        type_link += 'case-study';
                        break;
                }

                return type_link + '/' + center.spotlight.id;
            },
        },
    };
</script>
