<template>
    <div
        class="map-lessons"
        :class="{
            'map-loading': loading,
            'map-mode-mini': mode === 'mini',
        }"
    >
        <h1 v-if="label" class="map-lessons-label map-lineunder">{{ label }}</h1>
        <loader />
        <slot></slot>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';

    export default
    {
        name: 'lessons',
        components:
        {
            Loader,
        },
        props:
        {
            loading: Boolean,
            label: String,
            mode: String,
        },
    }
</script>
