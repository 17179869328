import { ApiService } from './api-service';

const UserService =
{
    all(params)
    {
        return ApiService.get('/api/users', params);
    },

    get_user()
    {
        return ApiService.get('/api/user');
    },

    get(id)
    {
        return ApiService.get(`/api/users/${id}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/users/${id}`, data);
    },

    create(data)
    {
        return ApiService.post(`/api/users`, data);
    },

    signup(data)
    {
        return ApiService.post(`/api/users/signup`, data);
    },

    approve(id, data)
    {
        return ApiService.post(`/api/users/${id}/approve`, data);
    },

    remove( id)
    {
        return ApiService.delete(`/api/users/${id}`);
    },
};

export {UserService};
