<template>
    <div
        :class="{
            'map-focused': focused,
            'map-expanded': expanded,
            'has-label': label
        }"
        class="map-input map-basic-dropdown"
        v-on:click="expanded = !expanded"
        >
        <slot name="label">
            <label v-if="label">{{ label }}</label>
        </slot>
        <span class="map-dropdown-caption">{{ caption || placeholder }}</span>
        <ul class="map-dropdown-list">
            <li
                v-for="(option, key) in options"
                v-on:click="select_item(option)"
                :class="{'map-selected' : modelValue === option || option.title}"
                :key="'drpd_' + key"
                >
                {{ option || option.title }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default
    {
        name: 'basic-dropdown',
        props:
        {
            label: String,
            placeholder: String,
            modelValue: {required: !1},
            options:
            {
                type: Array,
                required: true,
                default: () => [],
            },
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                selected: false,
                focused: false,
                touched: false,
                expanded: false,
                caption: '',
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },
        },
        methods:
        {
            select_item(option)
            {
                let caption = option || option.title;
                let value = option || option.value;

                if (value === this.modelValue)
                {
                    value = '';
                    caption = '';
                }

                this.caption = caption;
                this.$emit('update:modelValue', value);
            },
        }
    }
</script>
