<template>
    <div class="main-layout" :class="route_class">
        <Header v-if="!$route.meta.only_when_logged_out && !$route.meta.no_header" :sticky="header_sticky"></Header>
        <perfect-scrollbar :options="{ suppressScrollX: true }">
            <div class="map-layout-content">
                <router-view v-slot="{ Component }">
                    <transition name="route" mode="out-in">
                        <component :is="Component"></component>
                    </transition>
                </router-view>
            </div>
        </perfect-scrollbar>
        <Footer v-if="$route.meta.only_when_logged_out && $route.meta.has_footer"></Footer>
        <AdminMenu v-if="!$route.meta.only_when_logged_out && !$route.meta.no_header && (user.role === 'super-admin' || user.role === 'admin')" />
        <button class="map-scroll-to-top" @click="scroll_to_top" :class="{ 'map-visible': show_scroll_to_top }">^</button>
    </div>
</template>

<script>
    import Header from './Header';
    import Footer from './Footer';
    import AdminMenu from './AdminMenu';

    export default
    {
        name: 'MainLayout',
        components:
        {
            Header,
            Footer,
            AdminMenu,
        },
        data()
        {
            return {
                header_sticky: false,
                show_scroll_to_top: false,
            };
        },
        mounted()
        {
            document.querySelector('#app .main-layout > .ps').onscroll = ((ev) =>
            {
                this.show_scroll_to_top = (ev.target.scrollTop > 300);
                this.header_sticky = (ev.target.scrollTop > 50);
            });
        },
        computed:
        {
            route_class()
            {
                const additional_layout_class = this.$route.meta.layout_class || '';

                return 'map-route-' + this.$route.name.toLowerCase().replace(/\s/, '-') + ' ' + additional_layout_class;
            },
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        watch:
        {
            $route()
            {
                this.scroll_to_top();
            },
        },
        methods:
        {
            scroll_to_top()
            {
                document.querySelector('#app .main-layout > .ps').scrollTo(0, 0, 'smooth');
            },
        },
    }
</script>
