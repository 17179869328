<template>
    <div
        class="map-image-gallery"
        :class="{
            'map-loading': loading,
        }"
        data-label="Image Gallery"
        >
        <loader />
        <Carousel :itemsToShow="1.95" :wrapAround="true" v-model="current_slide" :transition="500">
            <Slide v-for="(image, key) in images" :key="key">
                <img :src="image.src" />
            </Slide>
        </Carousel>
        <footer>
            <button class="map-button-prev" @click="prev_page">
                <i class="icon-left-arrow-icon"></i>
            </button>
            <button class="map-button-next" @click="next_page">
                <i class="icon-right-arrow-icon"></i>
            </button>
            <span>{{ display_index }}/{{ slide_count || '∞' }}</span>
        </footer>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import { Carousel, Slide } from 'vue3-carousel'

    import 'vue3-carousel/dist/carousel.css'

    export default
    {
        name: 'image-gallery',
        components:
        {
            Carousel,
            Slide,
            Loader,
        },
        props:
        {
            loading:
            {
                type: Boolean,
                default: false,
            },
            images:
            {
                type: Array,
                default: undefined,
            },
        },
        data()
        {
            return {
                current_slide: 0,
                slide_count: 10,
            };
        },
        mounted()
        {
            this.slide_count = this.images.length;
        },
        computed:
        {
            display_index()
            {
                return Math.max(1, Math.min(this.slide_count, this.current_slide + 1));
            },
        },
        methods:
        {
            prev_page()
            {
                this.current_slide--;

                // if (this.current_slide < 0)
                // {
                //     this.current_slide = this.slide_count - 1;
                // }

                // this.current_slide = Math.max(1, this.current_slide - 1);
            },
            next_page()
            {
                this.current_slide++;

                // if (this.current_slide > this.slide_count - 1)
                // {
                //     this.current_slide = 0;
                // }

                // this.current_slide = Math.min(this.slide_count - 1 , this.current_slide + 1);
            },
        },
    }
</script>
