<template>
    <div class="map-page map-page-resource-post">
        <div class="map-page-content map-center-layout">
            <h1 class="map-post-title">{{ post.title }}</h1>
            <p class="map-post-topic">{{ post.topic && post.topic[0] }}</p>
            <ul class="map-post-tags">
                <li v-for="(tag) in post.tags" :key="tag">#{{ tag }}</li>
            </ul>
            <div class="map-post-content page-dynamic-content">
                <p class="map-post-summary" v-html="post.summary"></p>
                <p v-html="post.content"></p>
                <pdf-viewer
                    v-if="pdf"
                    :src="pdf.url"
                    />
                <video-gallery
                    v-if="videos"
                    :videos="videos"
                />
            </div>
            <image-gallery v-if="false" :images="images" />
            <podcasts v-if="false" :audios="audios" />
        </div>
    </div>
</template>

<script>
    import ImageGallery from '@/components/ImageGallery';
    import Podcasts from '@/components/Podcasts';
    import PdfViewer from '@/components/PdfViewer';
    import VideoGallery from '@/components/VideoGallery';

    import { GuttenbergContent } from '@/plugins/guttenberg/content';

    import { PostService, PostType } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            ImageGallery,
            Podcasts,
            VideoGallery,
            PdfViewer,
        },
        props:
        {
            data: Object,
        },
        setup()
        {
            return {
                PostType: PostType,
            };
        },
        data()
        {
            return {
                loading: false,
                post: {},
                pdf: undefined,
                videos: undefined,
                images:
                [
                    { src: 'http://localhost/map-api/public/api/files/7' },
                    { src: 'http://localhost/map-api/public/api/files/30' },
                    { src: 'http://localhost:8080/img/blog.bd053f23.jpg' },
                ],
                audios:
                [
                    {
                        src: '/temp/audio.mp3',
                        title: 'Simple audio for test',
                        author: 'John Sep',
                        date: 'May 4, 2021',
                    },
                    {
                        src: '/temp/audio_2.mp3',
                        title: 'Simple audio for test 2',
                        author: 'John Sep',
                        date: 'May 4, 2021',
                    }
                ],
            };
        },
        async mounted()
        {
            this.loading = true;

            if (this.data)
            {
                this.post = this.data;
            }
            else
            {
                await this.get_post(this.$route.params.id);
            }

            this.parse_content(this.post.content);

            GuttenbergContent.init();

            this.loading = false;
        },
        methods:
        {
            async get_post(id)
            {
                this.post = (await PostService.get(PostType.RESOURCE, id)).data;
            },

            parse_content(content)
            {
                const temp_el = document.createElement('div');

                temp_el.innerHTML = content;

                const pdf_block_el = temp_el.querySelector('.pdf-block');
                const video_block_el = temp_el.querySelector('.video-block');

                if (pdf_block_el)
                {
                    this.pdf = JSON.parse(pdf_block_el.innerText.trim()) || undefined;
                }

                if (video_block_el)
                {
                    const videos = JSON.parse(video_block_el.innerText.trim()) || undefined;
                    this.videos = (videos !== undefined && !Array.isArray(videos) ? [videos] : videos);
                }
                // this.questions = JSON.parse(temp_el.querySelector('.check-quiz-block').innerText.trim()) || [];
                // this.todo = JSON.parse(temp_el.querySelector('.todo-block').innerText.trim()) || [];
            },
        },
    };
</script>
