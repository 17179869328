<template>
    <div
        class="map-pdf-viewer"
        :class="{
            'map-loading': loading,
            'map-fullscreen': fullscreen,
        }"
        >
        <loader />
        <perfect-scrollbar :options="{ suppressScrollX: true }">
            <pdf
                class="map-pdf-page"
                v-if="pdfdata"
                :src="pdfdata"
                :page="page"
                :resize="true"
                :scale="scale"
                >
            </pdf>
        </perfect-scrollbar>
        <footer>
            <a :href="src + '?download=1'" target="_blank">Download</a>
            <button class="map-button-fullscreen" @click="go_to_fullscreen">
                <i class="icon-expand"></i>
            </button>
            <button class="map-button-prev" @click="prev_page">
                <i class="icon-left-arrow-icon"></i>
            </button>
            <button class="map-button-next" @click="next_page">
                <i class="icon-right-arrow-icon"></i>
            </button>
            <span>{{ page }}/{{ page_count || '∞' }}</span>
        </footer>
    </div>
</template>

<script>
    import Pdf from 'pdfvuer';
    import Loader from  '@/components/Loader';

    import { TokenService } from '@/services';

    export default
    {
        name: 'pdf-viewer',
        components:
        {
            Pdf,
            Loader,
        },
        props:
        {
            loading: Boolean,
            src: String,
        },

        data()
        {
            return {
                page: 1,
                page_count: 0,
                scale: 0.1,
                fullscreen: false,
                pdfdata: undefined,
            };
        },
        async mounted()
        {
            await this.get_pdf();
        },
        methods:
        {
            async get_pdf()
            {
                this.pdfdata = Pdf.createLoadingTask({
                    url: this.src,
                    withCredentials: true,
                    httpHeaders:
                    {
                        'Authorization': 'Bearer ' + TokenService.get_token(),
                    },
                });

                this.pdfdata.then((pdf) =>
                {
                    this.page_count = pdf.numPages;
                    this.page = 1;
                });
            },
            go_to_fullscreen()
            {
                this.fullscreen = !this.fullscreen;
            },
            prev_page()
            {
                this.page = Math.max(1, this.page - 1);
            },
            next_page()
            {
                this.page = Math.min(this.page_count, this.page + 1);
            },
        },
    }
</script>

<style src="pdfvuer/dist/pdfvuer.css"></style>
